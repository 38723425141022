import { ReactNode } from 'react';
import images from 'assets/images';
import './style.scss';

interface TransactionSuccessProps {
  children: ReactNode;
}

const TransactionSuccess: React.FC<TransactionSuccessProps> = (props) => {
  const { children } = props;
  return (
    <div className="message-success">
      <img src={images.TransactionComplete} alt="Transaction Error"></img>
      <div className="message-success__message-content">{children}</div>
    </div>
  );
};
export default TransactionSuccess;
