import React from 'react';
import configureStore from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import AppContainer from 'components/layout/AppContainer';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Harmony } from '@harmony-js/core';
import 'antd/dist/antd.min.css';
import RoutesContainer from 'components/layout/routes/RoutesContainer';

function getLibrary(provider: any): Web3Provider | Harmony {
  var library: Web3Provider | Harmony;

  if (provider?.chainType === 'hmy') {
    library = provider.blockchain;
  } else {
    library = new Web3Provider(provider);
    library.pollingInterval = 12000;
  }

  return library;
}

function App() {
  const { store, persistor } = configureStore();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <AppContainer>
            <RoutesContainer />
          </AppContainer>
        </Web3ReactProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
