import { useMemo, useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { isMetaMaskInstalled, signWallet } from 'blockchain/helper/utils';
import {
  APP_NETWORKS_SUPPORT,
  LOGIN_STATUS,
  NETWORK_BSC,
  NETWORK_KCC,
  ROUTES,
  SWITCH_NETWORK,
} from 'common/constants';

import { ethers } from 'ethers';
import userServices from 'services/user';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import {
  setStatusAction,
  setUserSuccessAction,
} from 'store/actions/userAction';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Transaction from 'components/alert';

const useAuth = () => {
  const windowObj = window as any;
  const { account, library, activate, active } = useWeb3React();
  const [loading, setLoading] = useState<boolean>(false);
  const [check, setCheck] = useState(false);
  const [isNetworkPopup, setIsNetworkPopup] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const LOGIN_SIGN_MESSAGE = process.env.REACT_APP_LOGIN_SIGN_MESSAGE;
  const { ethereum } = windowObj;
  const network = ethereum?.networkVersion;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkNetwork = async (injected: any) => {
    if (
      network === NETWORK_BSC.CHAIN_ID_DECIMAL ||
      network === NETWORK_KCC.CHAIN_ID_DECIMAL
    ) {
      handleSign();
    } else {
      dispatch(setStatusAction(LOGIN_STATUS.CHANGE_NETWORK_METAMASK));
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeNetwork = async (chainId: any) => {
    if (
      network !== NETWORK_BSC.CHAIN_ID_DECIMAL ||
      network !== NETWORK_KCC.CHAIN_ID_DECIMAL
    ) {
      try {
        await ethereum?.request({
          method: 'wallet_switchEthereumChain',
          params: [SWITCH_NETWORK[chainId]],
        });
        setIsNetworkPopup(false);
      } catch (switchError: any) {
        if (switchError.code === 4902) {
          try {
            await ethereum?.request({
              method: 'wallet_addEthereumChain',
              params: [APP_NETWORKS_SUPPORT[chainId]],
            });
            setIsNetworkPopup(false);
          } catch (err) {
            console.error(err);
          }
        }
      }
    }
  };

  const handleSign = useCallback(async () => {
    if (library && account) {
      setLoading(true);
      if (!windowObj.ethereum) {
        throw new Error('No crypto wallet found. Please install it.');
      }
      const providers = new ethers.providers.Web3Provider(windowObj.ethereum);
      try {
        const signature = await signWallet(providers);
        const params = {
          signature,
          address: account,
          message: LOGIN_SIGN_MESSAGE || '',
        };
        const resultToken = await userServices.login(params);
        const statusRes = resultToken.status;
        const { accessToken } = resultToken.data.data;
        const status = LOGIN_STATUS.LOGED_IN;
        const user = {
          account,
          accessToken,
          status,
        };
        if (statusRes === 200 || statusRes === 201) {
          if (accessToken) {
            dispatch(setUserSuccessAction(user));
            dispatch(setStatusAction(status));
            localStorage.setItem('accessToken', accessToken);
            setTimeout(() => {
              setLoading(false);
              // history?.push(ROUTES.POOL_MANAGEMENT)
            }, 200);
          }
        }
      } catch (error) {
        const status = _.get(error, 'response.status', {});
        if (status === 403 || status === 404) {
          dispatch(setStatusAction(LOGIN_STATUS.NOT_ADMIN));
          setLoading(false);
        } else {
          setLoading(false);
          message.info({
            content: (
              <Transaction type="error">
                <span>
                  Metamask Message Signature: <br />
                  User denied message signature.
                </span>
              </Transaction>
            ),
            icon: <></>,
          });
        }
      }
    }
  }, [
    library,
    account,
    windowObj.ethereum,
    LOGIN_SIGN_MESSAGE,
    dispatch,
    history,
  ]);

  const login = useCallback(
    async (injected: any) => {
      if (!isMetaMaskInstalled()) {
        dispatch(setStatusAction(LOGIN_STATUS.METAMASK_UNAVAILABLE));
      }
      if (localStorage.getItem('accessToken')) {
        window.location.reload();
      } else if (isMetaMaskInstalled() && account && active) {
        try {
          checkNetwork(injected);
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      }
      if (!account) {
        setLoading(true);
        await activate(injected);
        setLoading(false);
      }
    },
    [account, activate, active, checkNetwork, handleSign, network]
  );

  const context = useMemo(() => {
    return {
      account,
      login,
      loading,
      isNetworkPopup,
      setIsNetworkPopup,
      changeNetwork,
    };
  }, [account, login, loading, isNetworkPopup, changeNetwork]);
  return context;
};

export default useAuth;
