import React from 'react';
import { Button, Modal } from 'antd';
import { BSC_CHAIN_ID, KCC_CHAIN_ID } from 'common/constants';
import images from 'assets/images';

interface ChangeNetworkProps {
  isNetworkPopUp?: boolean;
  handleCancel?: () => void;
  changeNetwork?: any;
}

const ModalChangeNetwork: React.FC<ChangeNetworkProps> = (props) => {
  const { handleCancel, isNetworkPopUp, changeNetwork } = props;

  return (
    <Modal title="" open={isNetworkPopUp} footer={null} onCancel={handleCancel}>
      <div className="modal">
        <div className="modal__title">Choose network to continue</div>
        <div className="modal__network">
          <Button
            className="modal__network__btn-change-network"
            style={{ borderRadius: '8px', minWidth: '81px' }}
            onClick={() => {
              changeNetwork(BSC_CHAIN_ID);
            }}
          >
            <img src={images.BSC_LOGO} alt="bs"></img>
            BSC
          </Button>
          <Button
            className="modal__network__btn-change-network"
            style={{ borderRadius: '8px', minWidth: '81px' }}
            onClick={() => changeNetwork(KCC_CHAIN_ID)}
          >
            <img src={images.KCC_LOGO} alt="bsc-logo"></img>
            KCC
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalChangeNetwork;
