import { ServiceBase } from 'request/service-base';
import { LoginParams } from './params-type';

import queryString from 'query-string';
class UserServices extends ServiceBase {
  getDataPool = async (params: any) => {
    const queryParams = queryString.stringify(params);
    const res = await this.get(`api/v1/pools?${queryParams}`);
    return res.data;
  };
  login = async (params: LoginParams) => {
    const res = await this.post('api/v1/auth/login', params);
    return res;
  };
  getPoolDetail = async (id: any) => {
    const res = await this.get(`api/v1/pools/${id}`);
    return res.data;
  };
}

const userServices = new UserServices();

export default userServices;
