import { Col, Form, Row } from 'antd';
import BigNumber from 'bignumber.js';
import {
  exceptThisSymbols,
  LIMIT_CHARACTER,
  NETWORK_BSC,
  NETWORK_KCC,
} from 'common/constants';
import InputForm from 'components/common/InputForm';
import { ethers } from 'ethers';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { preventCharacterLimit, removeZeroNumber } from 'utils/function';
import './style.scss';

interface DynamicSettingProps {
  renderLabelRequired: (title: string) => JSX.Element;
  renderLabel: (title: string) => JSX.Element;
  renderTitle: (title: string) => JSX.Element;
  form?: any;
  chain: string;
  setDisableCreate: React.Dispatch<React.SetStateAction<boolean>>;
}
const DynamicSetting: React.FC<DynamicSettingProps> = (props) => {
  const {
    renderLabelRequired,
    renderLabel,
    form,
    chain,
    setDisableCreate,
    renderTitle,
  } = props;
  const preventDecimal = (e: any) => {
    exceptThisSymbols.includes(e.key) && e.preventDefault();
  };
  const windowObj = window as any;
  const { ethereum } = windowObj;

  useEffect(() => {
    const interval = setInterval(async () => {
      const provider = new ethers.providers.JsonRpcProvider(
        chain === 'bsc' ? NETWORK_BSC.RPC_URLS : NETWORK_KCC.RPC_URLS
      );
      const blockNumber = await provider.getBlockNumber();
      form.setFieldsValue({ _currentBlock: blockNumber });
      if (
        form.getFieldValue('_startBlock') &&
        form.getFieldValue('_startBlock') < blockNumber
      )
        form.validateFields(['_startBlock']);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [ethereum, form, , chain]);

  const onChangeMulti = (
    e: React.ChangeEvent<HTMLInputElement>,
    limitCharacter = LIMIT_CHARACTER
  ) => {
    setDisableCreate(true);
    const currentBlock = Number(form.getFieldValue('_currentBlock'));
    const multiplierPeriod = Number(form.getFieldValue('_bonusEndBlock'));
    const startBlock = Number(form.getFieldValue('_startBlock'));
    const { name, value } = e.target;

    if (
      currentBlock !== undefined &&
      multiplierPeriod !== undefined &&
      multiplierPeriod >= 0 &&
      startBlock !== undefined &&
      startBlock > 0
    ) {
      const endBlock = new BigNumber(startBlock)
        .plus(
          new BigNumber(multiplierPeriod)
            .multipliedBy(24)
            .multipliedBy(3600)
            .dividedBy(3)
        )
        .toFixed();
      form.setFieldsValue({ _endBlock: endBlock });
      setDisableCreate(false);
    } else {
      form.setFieldsValue({ _endBlock: 0 });
      setDisableCreate(false);
    }

    form.setFieldValue([name], value.slice(0, limitCharacter));
  };

  const preventPasteText = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const text = e.clipboardData.getData('text');
    const arrayText = text.split('');
    arrayText?.map((item) => {
      if (_.includes(exceptThisSymbols, item)) {
        e.preventDefault();
      }
    });
  };

  return (
    <Row className="container" gutter={[16, 0]}>
      <div className="container__header">
        <span className="title font-500">
          {renderTitle('Multiplier setting')}
        </span>
        <span className="warning font-400">
          *After the end block, the reward will be calculated with a multiplier
          of 1
        </span>
      </div>
      <Col span={8}>
        <span className="title font-500">
          {renderLabelRequired('Multiplier')}
        </span>
        <Form.Item
          name="_bonusMultiplier"
          rules={[{ required: true, message: 'Please fill in this field' }]}
        >
          <InputForm
            onKeyDown={preventDecimal}
            onPaste={(e) => preventPasteText(e)}
            onBlur={(e) => removeZeroNumber(e, form)}
            onChange={(e) => preventCharacterLimit(e, form)}
            name="_bonusMultiplier"
            type="number"
            placeholder="Enter"
            style={{ marginTop: '5px' }}
          />
        </Form.Item>

        <span className="title font-500" style={{ marginTop: '20px' }}>
          {renderLabelRequired('Multiplier Period (in days)')}
        </span>
        <Form.Item
          name="_bonusEndBlock"
          rules={[{ required: true, message: 'Please fill in this field' }]}
        >
          <InputForm
            name="_bonusEndBlock"
            form={form}
            onChange={(e) => onChangeMulti(e, 7)}
            onPaste={(e) => preventPasteText(e)}
            onKeyDown={preventDecimal}
            onBlur={(e) => removeZeroNumber(e, form)}
            type="number"
            placeholder="Enter"
            style={{ marginTop: '5px' }}
          />
        </Form.Item>
      </Col>

      <Col span={8} style={{ display: 'flex', alignItems: 'end' }}>
        <div style={{ width: '100%' }}>
          <span className="title font-500" style={{ marginTop: '20px' }}>
            {renderLabelRequired('Start block')}
          </span>
          <Form.Item
            name="_startBlock"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const currentBlock = Number(
                    form.getFieldValue('_currentBlock')
                  );
                  if (!value)
                    return Promise.reject(
                      new Error('Please fill in this field')
                    );
                  if (Number(value) < currentBlock) {
                    return Promise.reject(
                      new Error(
                        'Start block must be greater or equal to current block'
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputForm
              name="_startBlock"
              type="number"
              onPaste={(e) => preventPasteText(e)}
              onKeyDown={preventDecimal}
              onChange={(e) => onChangeMulti(e, 15)}
              placeholder="Enter"
              style={{ marginTop: '5px' }}
            />
          </Form.Item>
        </div>
      </Col>

      <Col span={8}>
        <span className="title font-500">{renderLabel('Current block')}</span>
        <Form.Item name="_currentBlock">
          <InputForm
            type="text"
            style={{ marginTop: '5px' }}
            disabled
            name="_currentBlock"
          />
        </Form.Item>

        <span className="title font-500" style={{ marginTop: '20px' }}>
          {renderLabel('End block')}
        </span>
        <Form.Item name="_endBlock">
          <InputForm
            type="text"
            style={{ marginTop: '5px' }}
            disabled
            name="_endBlock"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default DynamicSetting;
