import { useEffect, useState } from 'react';
import { Input, TableColumnsType, Tooltip } from 'antd';
import { LIMIT, ROUTES, STATUS_POOL } from 'common/constants';
import DropDownCustom from 'components/common/DropDown';
import { useHistory } from 'react-router-dom';
import userServices from 'services/user';
import Button from '../../components/common/Button';
import Table from '../Table';
import './style.scss';
import _, { debounce } from 'lodash';
import BigNumber from 'bignumber.js';
import icons from 'assets/themes/svgs';
import { formatLiquid, numberWithCommasAutoDecimal } from 'utils/function';
import moment from 'moment';

const filterOption = [
  { key: 'LIVE', label: 'Live' },
  { key: 'ENDED', label: 'Ended' },
];

const renderTitle = (text: string) => <div className="table-title">{text}</div>;

const formatPercent = (value: string) => {
  const percent = new BigNumber(value);
  if (percent.gt(new BigNumber(1000000))) {
    if (percent.isInteger()) {
      const result =
        value.substring(-1, 3) +
        '..' +
        value.substring(value.length - 2, value.length);
      return result.toString();
    } else {
      const convertRes = parseFloat(value).toFixed(2);
      const result =
        convertRes.substring(-1, 3) +
        '..' +
        convertRes.substring(
          convertRes.length - 5,
          convertRes.toString().length
        );
      return result.toString();
    }
  }
  if (percent.lt(new BigNumber(0.01))) {
    return '<0.01';
  }
  if (percent.isInteger()) {
    return value;
  } else {
    return parseFloat(value).toFixed(2);
  }
};

const renderStatus = (status: string) => {
  switch (status) {
    case STATUS_POOL.ENDED:
      return (
        <div className="button-status button-end">
          <span>Ended</span>
        </div>
      );
    case STATUS_POOL.LIVE:
      return (
        <div className="button-status button-live">
          <span>Live</span>
        </div>
      );
  }
};

const calculateLiquidity = (data: any) => {
  let liquidity = new BigNumber(0);
  let priceZero = false;
  data.tokenPools.map((item: any) => {
    if (Number(item.stakeToken.usdPrice) === 0) priceZero = true;
    const convertTotalStake = new BigNumber(item.totalStake).multipliedBy(
      new BigNumber(10).pow(-item.stakeToken.decimal)
    );
    liquidity = BigNumber.sum(
      0,
      liquidity,
      new BigNumber(convertTotalStake).multipliedBy(item.stakeToken.usdPrice)
    );
    return liquidity;
  });
  if (priceZero) liquidity = new BigNumber(0);
  return liquidity.toString();
};

const PoolManagement = () => {
  const columns: TableColumnsType<any> = [
    {
      title: () => renderTitle('Pool Index'),
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: () => renderTitle('Stake tokens'),
      dataIndex: 'stakeTokens',
      key: 'stakeTokens',
      align: 'left',
      render: (text: string, row) => (
        <Tooltip placement="top" title={text.length > 15 ? text : ''}>
          <p className="thee-dot">
            {text}
            <span className="date-tag">
              {moment
                .unix(new Date(row.createdAt).getTime() / 1000)
                .format('MM.DD')}
            </span>
          </p>
        </Tooltip>
      ),
    },
    {
      title: () => renderTitle('Reward tokens'),
      dataIndex: 'rewardTokens',
      key: 'rewardTokens',
      align: 'left',
      render: (text: string) => (
        <Tooltip placement="top" title={text.length > 15 ? text : ''}>
          <p className="thee-dot">{text}</p>
        </Tooltip>
      ),
    },
    {
      title: () => renderTitle('APR'),
      dataIndex: 'apr',
      key: 'apr',
      align: 'center',
      render: (text: string, row) => {
        const type = row?.aprType;
        return (
          <div className="apr-container">
            <div className="apr-container__apr">
              <Tooltip
                placement="top"
                title={numberWithCommasAutoDecimal(text)}
              >
                <p
                  style={{
                    width: 'max-content',
                    marginRight: '0px',
                    marginLeft: 'auto',
                  }}
                >
                  {formatLiquid(text) === '0' ? '0%' : `${formatLiquid(text)}%`}
                </p>
              </Tooltip>
            </div>
            <div className="apr-container__typContainer">
              <div
                className={`apr-container__typContainer__poolType ${
                  type === 'DYNAMIC' ? 'dynamic' : 'fixed'
                } `}
              >
                {row.aprType}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => renderTitle('Liquidity ($)'),
      key: 'liquidity',
      align: 'center',
      render: (data: object) => (
        <Tooltip
          placement="top"
          title={numberWithCommasAutoDecimal(calculateLiquidity(data))}
        >
          <p>
            {calculateLiquidity(data) !== '0'
              ? formatLiquid(calculateLiquidity(data))
              : '0'}
          </p>
        </Tooltip>
      ),
    },
    {
      title: () => renderTitle('Chain'),
      dataIndex: 'chain',
      key: 'chain',
      align: 'center',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: () => renderTitle('Status'),
      dataIndex: 'status',
      key: 'status',
      align: 'right',
      render: (text: string) => <span>{renderStatus(text)}</span>,
    },
  ];

  const history = useHistory();
  const handleClick = () => {
    history.push(ROUTES.CREATE_POOL);
  };
  const [filter, setFilter] = useState({
    page: 1,
    limit: LIMIT,
    status: '',
  });

  const [dataPool, setDataPool] = useState([]);
  const [tokens, setSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState([]);
  const [total, setTotal] = useState<number>(40);
  const [valueInp, setValueInp] = useState('');

  window.scrollTo(0, 0);

  const getData = async () => {
    let res;
    res = await userServices.getDataPool(filter);
    if (tokens || filter.status) {
      res = await userServices.getDataPool({ ...filter, tokens: tokens });
    }
    if (res.data) {
      res.data.result.map((element: any, index: number) => {
        element.index = index + 1;
      });
      setDataPool(res.data.result);
      setTotal(res.data.total);
    }
    if (filter.status === undefined) {
      setDataPool([]);
      setTotal(0);
    }
  };

  useEffect(() => {
    getData();
  }, [filter, tokens]);

  useEffect(() => {
    const arrayStatus: any = ['LIVE', 'ENDED'];
    setFilterStatus(arrayStatus);
  }, []);

  const handleFilter = (list: any) => {
    if (list.length === filterOption.length) {
      setFilter({ ...filter, status: '', page: 1 });
    } else {
      setFilter({ ...filter, status: list[0], page: 1 });
    }
    setFilterStatus(list);
  };

  const handleChangePage = (currentPage: number) => {
    setFilter({ ...filter, page: currentPage });
  };

  const onSearch = () => {
    setSearch(valueInp);
    setFilter({ ...filter, page: 1 });
  };
  const onChangeSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setFilter({ ...filter, page: 1 });
  }, 500);

  return (
    <div className="pool-management">
      <div className="content">
        <div className="head">
          <span className="text">Pool Management</span>
          <div className="right">
            <Input
              placeholder="Search"
              className="search"
              onChange={onChangeSearch}
              onPressEnter={onSearch}
              suffix={<img src={icons.Search} onClick={onSearch} />}
            />
            <DropDownCustom
              filterOption={filterOption}
              handleFilter={handleFilter}
              filterStatus={filterStatus}
            >
              Status
            </DropDownCustom>
            <Button
              onClick={handleClick}
              style={{ padding: '0px', fontSize: '12px', height: '40px' }}
            >
              Create a new pool
            </Button>
          </div>
        </div>

        <Table
          key="id"
          data={dataPool}
          columns={columns}
          total={total}
          page={filter.page}
          handleChangePage={handleChangePage}
          routeDetail={ROUTES.POOL_DETAIL}
        />
      </div>
    </div>
  );
};

export default PoolManagement;
