import { ROUTES } from 'common/constants';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import DefaultLayout from '../default-layout/DefaultLayout';

const PrivateRoute = (props: RouteProps) => {
  //todo: handle private route
  const user = useSelector((state: any) => state.user);
  const { accessToken } = user;

  if (!accessToken) {
    return (
      <Route
        render={(props) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
  if (accessToken && props.path === '/') {
    return (
      <Route
        render={(props) => (
          <Redirect
            to={{
              pathname: ROUTES.POOL_MANAGEMENT,
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  return (
    <DefaultLayout>
      <Route {...props} />
    </DefaultLayout>
  );
};

export default PrivateRoute;
