import React from 'react';
import { Switch, SwitchProps } from 'antd';
import './style.scss';

interface SwitchCustomProps extends SwitchProps {}

const SwitchCustom: React.FC<SwitchCustomProps> = (
  props: SwitchCustomProps
) => {
  return (
    <div className="switch-custom">
      <Switch {...props} />
    </div>
  );
};
export default SwitchCustom;
