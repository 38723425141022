import { Form, FormInstance, Input, Spin } from 'antd';
import icons from 'assets/themes/svgs';
import { getInfoToken } from 'blockchain/helper/web3';
import { BSC_CHAIN_ID, KCC_CHAIN_ID } from 'common/constants';
import React, { useMemo } from 'react';
import Web3 from 'web3';
import InputForm from '../InputForm';
import { LoadingOutlined } from '@ant-design/icons';
import TokenInfo from 'pages/PoolManagement/CreatePool/form/TokenInfo';
import { useState } from 'react';
import './style.scss';
import { useEffect } from 'react';

interface InputTokenProps {
  chain?: string;
  form?: FormInstance;
  index?: number;
  nameForm: string | any;
  fields?: any;
  symbolToken?: String[];
  setSymbolToken?: React.Dispatch<React.SetStateAction<String[]>>;
}
type TokenInfo = {
  name: any;
  symbol: any;
  decimals: any;
  address?: string;
};

type FiledsType = {
  [key: string]: any;
};
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const InputToken: React.FC<InputTokenProps> = (props) => {
  const { chain, form, nameForm } = props;
  const kcc_chain = KCC_CHAIN_ID;
  const bsc_chain = BSC_CHAIN_ID;
  const web3 = new Web3();

  const [statusToken, setStatusToken] = useState<any>(null);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>();
  const [token0Symbol, setToken0Symbol] = useState('');
  const [token1Symbol, setToken1Symbol] = useState('');
  const apr = form?.getFieldValue('apr');
  const fields = form?.getFieldValue('fields');

  const convertTokenAddress = (str: string) => {
    if (/^0x/.test(str)) {
      return str.slice(2);
    } else {
      return str;
    }
  };

  const checkToken = (value: string, trimSpaceValue: string) => {
    if (
      convertTokenAddress(value?.trim()) === convertTokenAddress(trimSpaceValue)
    ) {
      return true;
    }
    return false;
  };
  const arrayFields = useMemo(() => {
    return form?.getFieldsError([
      ['fields', 0, '_stakeToken'],
      ['fields', 1, '_stakeToken'],
      ['fields', 2, '_stakeToken'],
    ]) as any;
  }, [fields]);

  useEffect(() => {
    if (fields.length === 1) {
      arrayFields.map((item: any) => {
        if (item.errors[0] === 'Duplicate values are not allowed.') {
          form?.validateFields([
            ['fields', 0, '_stakeToken'],
            ['fields', 1, '_stakeToken'],
            ['fields', 2, '_stakeToken'],
          ]);
        }
      });
    }
  }, [fields]);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const networkChain = chain === 'bsc' ? bsc_chain : kcc_chain;
    const value = e.target.value?.trim();
    const fields = form?.getFieldValue('fields');
    const stakeToken = fields[nameForm[0]]._stakeToken;
    const erc20Token = await getInfoToken(value, networkChain);
    if (erc20Token && apr === 'fixed') {
      if (stakeToken) {
        fields[nameForm[0]] = { ...fields[nameForm[0]], _saleToken: value };
        form?.setFieldsValue({ fields });
        form?.validateFields([['fields', nameForm[0], '_saleToken']]);
      }
    }
  };

  const disableInput = () => {
    if (apr === 'fixed' && nameForm[1] === '_saleToken') return true;
    else return false;
  };

  return (
    <div className="input-token">
      <div className="input-token__input-stake">
        <Form.Item
          style={{ margin: 0, width: '100%' }}
          name={nameForm}
          dependencies={['chain', 'apr']}
          rules={[
            { required: true, message: 'Please fill in this field' },
            ({ getFieldValue }) => ({
              async validator(_, value) {
                const trimSpaceValue = value?.trim();
                const fieldsValue = form?.getFieldValue('fields');
                const apr = getFieldValue('apr');
                const stakeToken = fieldsValue[nameForm[0]]._stakeToken;
                const saleToken = fieldsValue[nameForm[0]]._saleToken;
                if (!value) {
                  setStatusToken(null);
                  return;
                }

                const isAddress = web3.utils.isAddress(trimSpaceValue);
                if (!isAddress) {
                  setStatusToken('invalid');
                  setTokenInfo(undefined);
                  return Promise.reject(
                    new Error(`This token address is invalid`)
                  );
                }
                //check duplicate and refill reward token
                if (apr === 'fixed') {
                  const fields = form?.getFieldValue('fields');
                  const stakeToken = fields[nameForm[0]]._stakeToken;
                  const rewardToken = fields[nameForm[0]]._saleToken;
                  if (
                    rewardToken !== undefined &&
                    stakeToken !== undefined &&
                    stakeToken.trim() !== rewardToken.trim()
                  ) {
                    if (
                      `fields_${nameForm[0]}${nameForm[1]}` ===
                      `fields_${nameForm[0]}_stakeToken`
                    ) {
                      form?.setFields([
                        {
                          name: ['fields', nameForm[0], '_saleToken'],
                          errors: [
                            'Reward token address must be the same as stake token address',
                          ],
                        },
                      ]);
                    }
                    if (nameForm[1] === `_saleToken`) {
                      setStatusToken('invalid');
                      return Promise.reject(
                        new Error(
                          `Reward token address must be the same as stake token address`
                        )
                      );
                    }
                  }
                }
                setStatusToken('loading');
                const networkChain = chain === 'bsc' ? bsc_chain : kcc_chain;
                const erc20Token = await getInfoToken(
                  trimSpaceValue,
                  networkChain
                );
                //invalid erc20Token
                if (!erc20Token) {
                  setStatusToken('invalid');
                  setTokenInfo(undefined);
                  return Promise.reject(
                    new Error(`Could not find the specified address.`)
                  );
                }
                const fields = form?.getFieldValue('fields');
                let singleToken0;
                let singleToken1;
                //check is lp token
                if (erc20Token.token0 && erc20Token.token1) {
                  const lpToken0 = await getInfoToken(
                    erc20Token.token0,
                    networkChain
                  );
                  const lpToken1 = await getInfoToken(
                    erc20Token.token1,
                    networkChain
                  );
                  singleToken0 = lpToken0?.symbol;
                  singleToken1 = lpToken1?.symbol;
                  setToken0Symbol(lpToken0?.symbol);
                  setToken1Symbol(lpToken1?.symbol);
                } else {
                  setToken0Symbol('');
                  setToken1Symbol('');
                }
                setTokenInfo(erc20Token);

                if (fields?.[nameForm[0]]) {
                  let symbol = 'Token symbol';
                  if (singleToken0 && singleToken1) {
                    symbol = `${singleToken0}/${singleToken1}`;
                  } else {
                    symbol = erc20Token.symbol;
                  }
                  fields[nameForm[0]][nameForm[1] + 'symbol'] = symbol;
                  fields[nameForm[0]] = {
                    ...fields[nameForm[0]],
                  };
                }
                form?.setFieldsValue({ fields });
                if (
                  nameForm[1] === '_stakeToken' &&
                  fieldsValue?.filter((field: FiledsType, index: number) =>
                    checkToken(field?.[nameForm[1]], trimSpaceValue)
                  ).length !== 1
                ) {
                  setStatusToken('invalid');
                  return Promise.reject(
                    new Error('Duplicate values are not allowed.')
                  );
                }

                if (apr === 'fixed') {
                  if (stakeToken && !saleToken) {
                    fields[nameForm[0]] = {
                      ...fields[nameForm[0]],
                      _saleToken: value,
                    };
                    form?.setFieldsValue({ fields });
                    form?.validateFields([
                      ['fields', nameForm[0], '_saleToken'],
                    ]);
                  }
                  if (stakeToken && stakeToken !== saleToken) {
                    fields[nameForm[0]] = {
                      ...fields[nameForm[0]],
                      _saleToken: value,
                    };
                    form?.setFieldsValue({ fields });
                    form?.validateFields([
                      ['fields', nameForm[0], '_saleToken'],
                    ]);
                  }
                }
                setStatusToken('valid');
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputForm
            forminput={form}
            onChange={onChange}
            disabled={disableInput()}
            placeholder="Enter"
            type="text"
          />
        </Form.Item>
        <Form.Item
          name={[nameForm[0], nameForm[1] + 'symbol']}
          hidden
          initialValue={''}
        >
          <Input />
        </Form.Item>

        {statusToken !== null ? (
          statusToken === 'invalid' ? (
            <div className="input-token__input-stake__invalid">
              <img
                src={icons.InValid}
                className="input-token__input-stake__invalid__token-status-invalid"
                alt="invalid"
              />
            </div>
          ) : statusToken === 'valid' ? (
            <img
              src={icons.Valid}
              className="input-token__input-stake__token-status"
              alt="valid"
            />
          ) : (
            <Spin
              indicator={antIcon}
              className="input-token__input-stake__token-status"
            />
          )
        ) : (
          <></>
        )}
      </div>

      {tokenInfo ? (
        <TokenInfo
          token0Symbol={token0Symbol}
          token1Symbol={token1Symbol}
          name={tokenInfo.name}
          symbol={tokenInfo.symbol}
          decimal={tokenInfo.decimals}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default InputToken;
