import React from 'react';
import './style.scss';
import { Dropdown, Button, Checkbox } from 'antd';
import icons from '../../../assets/themes/svgs';
interface DropDownCustomProps {
  children: React.ReactNode;
  filterOption?: Array<filterOption>;
  handleFilter?: (list: string[]) => void;
  filterStatus: Array<number>;
}
interface filterOption {
  key: number | string;
  label: string;
}
const DropDownCustom: React.FC<DropDownCustomProps> = (props) => {
  const {
    children,
    filterOption = [],
    handleFilter = () => {},
    filterStatus,
  } = props;

  const onChange = (list: any) => {
    handleFilter(list.length ? list : []);
  };

  const onCheckAllChange = (e: any) => {
    const checkAll = filterOption.map((option: any) => option.key);
    handleFilter(e.target.checked ? checkAll : []);
  };

  const handleCheckAll = () => {
    if (filterStatus.length !== filterOption.length) {
      return false;
    } else return true;
  };

  const MenuCustom = (filterOption: any) => {
    return (
      <div className="wrapper-option">
        <Checkbox
          className="wrapper-option__All"
          onChange={onCheckAllChange}
          checked={handleCheckAll()}
        >
          All
        </Checkbox>
        <Checkbox.Group
          className="wrapper-option__Group"
          onChange={onChange}
          value={filterStatus}
        >
          {filterOption.map((item: any) => (
            <Checkbox
              key={item.key}
              className="wrapper-option__Group__Check"
              value={item.key}
            >
              {item.label}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </div>
    );
  };
  return (
    // <div className='drop-down-custom'>
    <Dropdown
      overlay={MenuCustom(filterOption)}
      trigger={['click']}
      className="drop-down-custom"
    >
      <Button className="drop-down-custom__button">
        <div className="drop-down-custom__button__left">
          <img src={icons.IconFilter} alt="icon-filter"></img>
          <span>{children}</span>
        </div>
        <img src={icons.IconDropDown} alt="icon-drop-down"></img>
      </Button>
    </Dropdown>

    // </div>
  );
};

export default DropDownCustom;
