import { AllocationPoolInfo, LinerPoolInfo } from 'services/params-type';
import Web3 from 'web3';
import LinearABI from './../../blockchain/abi/LinearAbi.json';
import LinearABIKCC from './../../blockchain/abi/LinearAbiKcc.json';
import FactoryABI from './../../blockchain/abi/FactoryAbi.json';
import FactoryABIKCC from './../../blockchain/abi/FactoryAbiKcc.json';
import { BSC_CHAIN_ID, KCC_CHAIN_ID } from 'common/constants';
import erc20ABI from './../abi/ERC20.json';

const contractFactoryAddress = process.env
  .REACT_APP_POOL_FACTORY_ADDRESS as any as string;
const contractLinearAddress = process.env
  .REACT_APP_POOL_LINEAR_ADDRESS as any as string;
const contractFactoryAddressKCC = process.env
  .REACT_APP_POOL_FACTORY_KCC_ADDRESS as any as string;
const contractLinearAddressKCC = process.env
  .REACT_APP_POOL_LINEAR_KCC_ADDRESS as any as string;
const BSC_RPC = process.env.REACT_APP_BSC_RPC_URLS || '';
const KCC_RPC = process.env.REACT_APP_KCC_RPC_URLS || '';

export const getContract = (ABIContract: any, contractAddress: any) => {
  const windowObj = window as any;
  const { ethereum } = windowObj;
  const web3Instance = new Web3(ethereum);
  return new web3Instance.eth.Contract(ABIContract, contractAddress);
};

export const getContractByPoolChain = (
  ABIContract: any,
  contractAddress: any,
  chain: string
) => {
  const networkChain = chain === 'BSC' ? BSC_CHAIN_ID : KCC_CHAIN_ID;
  const rpc = RPC_URLS[networkChain];

  if (!rpc) {
    return undefined;
  }

  const web3Instance = new Web3(rpc);
  return new web3Instance.eth.Contract(ABIContract as any, contractAddress);
};

export const contractFactory = getContract(FactoryABI, contractFactoryAddress);
export const contractFactoryKCC = getContract(
  FactoryABIKCC,
  contractFactoryAddressKCC
);

export const contractLinear = getContract(LinearABI, contractLinearAddress);
export const contractLinearKCC = getContract(
  LinearABIKCC,
  contractLinearAddressKCC
);

// method call get with no params
export const getAdminAddress = async (contract: any) => {
  if (contract) {
    return await contract?.methods.ADMIN().call();
  }
};

// method call get with  params
export const getRoleAdmin = async (contract: any, address: string) => {
  if (contract) {
    return await contract?.methods.getRoleAdmin(address).call();
  }
};

//method send createLinerPool
export const createLinerPool = async (
  contract: any,
  poolInfo: LinerPoolInfo,
  address: string
): Promise<any> => {
  const {
    _stakeToken,
    _saleToken,
    _stakedTokenRate,
    _APR,
    _cap,
    _startTimeJoin,
    _lockDuration,
    _rewardDistributor,
  } = poolInfo;
  return await contract?.methods
    .createLinerPool(
      _stakeToken,
      _saleToken,
      _stakedTokenRate,
      _APR,
      _cap,
      _startTimeJoin,
      _lockDuration,
      _rewardDistributor
    )
    .send({
      from: address,
    });
};
//Create Allocation Pool
export const createAllocationPool = async (
  contract: any,
  poolInfo: AllocationPoolInfo,
  address: string
): Promise<any> => {
  const {
    _lpToken,
    _rewardToken,
    _stakedTokenRate,
    _bonusMultiplier,
    _startBlock,
    _bonusEndBlock,
    _lockDuration,
    _rewardDistributor,
    _tokenPerBlock,
  } = poolInfo;
  return await contract?.methods
    .createAllocationPool(
      _lpToken,
      _rewardToken,
      _stakedTokenRate,
      _bonusMultiplier,
      _startBlock,
      _bonusEndBlock,
      _lockDuration,
      _rewardDistributor,
      _tokenPerBlock
    )
    .send({
      from: address,
    });
};

export const RPC_URLS = {
  [BSC_CHAIN_ID]: BSC_RPC,
  [KCC_CHAIN_ID]: KCC_RPC,
};

export const getInfoToken = async (
  tokenAddress: string,
  currentNetWork: string
) => {
  try {
    const rpc = RPC_URLS[currentNetWork];
    if (!rpc) {
      return undefined;
    }
    const web3Instance = new Web3(rpc);

    const contract = new web3Instance.eth.Contract(
      erc20ABI as any,
      tokenAddress
    );

    if (contract) {
      let token0 = '';
      let token1 = '';
      const tokenName = await contract.methods.name().call();
      const tokenSymbol = await contract.methods.symbol().call();
      const tokenDecimals = await contract.methods.decimals().call();

      try {
        token0 = await contract.methods.token0().call();
        token1 = await contract.methods.token1().call();
      } catch (error) {
        console.error(error);
      }
      return {
        name: tokenName,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        address: tokenAddress,
        token0: token0,
        token1: token1,
      };
    }
  } catch (err) {
    console.error(err);
  }
};

export const unpauseContract = async (address: string, addressPool: string) => {
  try {
    let contract = getContract(LinearABI, addressPool);
    if (contract) {
      return await contract?.methods.unpauseContract().send({
        from: address,
      });
    }
  } catch (err) {
    console.error(err);
  }
};
