import IconDropDown from '../svgs/IconDropDown.svg';
import IconFilter from '../svgs/IconFilter.svg';
import IconClose from '../svgs/Close.svg';
import Arrow from '../svgs/Arrow.svg';
import Frame1 from '../svgs/Frame1.svg';
import Frame2 from '../svgs/Frame2.svg';
import LockIcon from '../svgs/lock_icon.svg';
import PoolIcon from '../svgs/pool_icon.svg';
import Wallet from '../svgs/Wallet.svg';
import Disconnect from '../svgs/sign-out.svg';
import ArrowLeft from '../svgs/ArrowLeft.svg';
import PercentIcon from '../svgs/percent.svg';
import Delete from '../svgs/Delete.svg';
import AddToken from '../svgs/AddToken.svg';
import IconPoolManagement from '../svgs/IconPoolManagement.svg';
import AstroneFiLogo from '../svgs/AstroneFi.svg';
import AstroneLogo from '../svgs/AstroneLogo.svg';
import UnionRed from '../svgs/UnionRed.svg';
import Valid from '../svgs/Valid.svg';
import InValid from '../svgs/Invalid.svg';
import SearchNoData from '../svgs/SearchNoData.svg';
import Search from '../svgs/Search.svg';
import LogoDefault from '../svgs/Logo_Default.svg';

import coinAlgorandLogo from '../coin-logo/algorand-algo-logo.svg';
import coinAvalancheLogo from '../coin-logo/avalanche-avax-logo.svg';
import coinAttentionLogo from '../coin-logo/basic-attention-token-bat-logo.svg';
import coinUsdLogo from '../coin-logo/binance-usd-busd-logo.svg';
import coinBitcoinLogo from '../coin-logo/bitcoin-btc-logo.svg';
import coinBNBLogo from '../coin-logo/bnb-bnb-logo.svg';
import coinCardanoLogo from '../coin-logo/cardano-ada-logo.svg';
import coinChainLinkLogo from '../coin-logo/chainlink-link-logo.svg';
import coinCronosLogo from '../coin-logo/cronos-cro-logo.svg';
import coinDashLogo from '../coin-logo/dash-dash-logo.svg';
import coinDogeLogo from '../coin-logo/dogecoin-doge-logo.svg';
import coinEnjinLogo from '../coin-logo/enjin-coin-enj-logo.svg';
import coinEthereumClassicLogo from '../coin-logo/ethereum-classic-etc-logo.svg';
import coinEthLogo from '../coin-logo/ethereum-eth-logo.svg';
import coinEthEnsLogo from '../coin-logo/ethereum-name-service-ens-logo.svg';
import coinFtxLogo from '../coin-logo/ftx-token-ftt-logo.svg';
import coinKcsLogo from '../coin-logo/kucoin-token-kcs-logo.svg';
import coinLiteLogo from '../coin-logo/litecoin-ltc-logo.svg';
import coinMoneroLogo from '../coin-logo/monero-xmr-logo.svg';
import coinDaiLogo from '../coin-logo/multi-collateral-dai-dai-logo.svg';
import coinNearLogo from '../coin-logo/near-protocol-near-logo.svg';
import coinRoseLogo from '../coin-logo/oasis-network-rose-logo.svg';
import coinPancakeLogo from '../coin-logo/pancakeswap-cake-logo.svg';
import coinPolkadotLogo from '../coin-logo/polkadot-new-dot-logo.svg';
import coinPolygonLogo from '../coin-logo/polygon-matic-logo.svg';
import coinShibaLogo from '../coin-logo/shiba-inu-shib-logo.svg';
import coinSolanaLogo from '../coin-logo/solana-sol-logo.svg';
import coinStackLogo from '../coin-logo/stacks-stx-logo.svg';
import coinStellarLogo from '../coin-logo/stellar-xlm-logo.svg';
import coinUsdtLogo from '../coin-logo/tether-usdt-logo.svg';
import coinTronLogo from '../coin-logo/tron-trx-logo.svg';
import coinUniswapLogo from '../coin-logo/uniswap-uni-logo.svg';
import coinLeoLogo from '../coin-logo/unus-sed-leo-leo-logo.svg';
import coinUsdcLogo from '../coin-logo/usd-coin-usdc-logo.svg';
import coinWbtcLogo from '../coin-logo/wrapped-bitcoin-wbtc-logo.svg';
import coinXrpLogo from '../coin-logo/xrp-xrp-logo.svg';

const icons = {
  IconDropDown,
  IconFilter,
  IconClose,
  Arrow,
  Frame1,
  Frame2,
  LockIcon,
  PoolIcon,
  Wallet,
  Disconnect,
  ArrowLeft,
  PercentIcon,
  Delete,
  AddToken,
  IconPoolManagement,
  AstroneFiLogo,
  AstroneLogo,
  UnionRed,
  Valid,
  InValid,
  SearchNoData,
  Search,
  LogoDefault,
};

export const coin = {
  coinAlgorandLogo,
  coinAvalancheLogo,
  coinAttentionLogo,
  coinUsdLogo,
  coinBitcoinLogo,
  coinBNBLogo,
  coinCardanoLogo,
  coinChainLinkLogo,
  coinCronosLogo,
  coinDashLogo,
  coinDogeLogo,
  coinEnjinLogo,
  coinEthereumClassicLogo,
  coinEthLogo,
  coinEthEnsLogo,
  coinFtxLogo,
  coinKcsLogo,
  coinLiteLogo,
  coinMoneroLogo,
  coinDaiLogo,
  coinNearLogo,
  coinRoseLogo,
  coinPancakeLogo,
  coinPolkadotLogo,
  coinPolygonLogo,
  coinShibaLogo,
  coinSolanaLogo,
  coinStackLogo,
  coinStellarLogo,
  coinUsdtLogo,
  coinTronLogo,
  coinUniswapLogo,
  coinLeoLogo,
  coinUsdcLogo,
  coinWbtcLogo,
  coinXrpLogo,
};

export default icons;
