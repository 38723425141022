import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import SideBar from '../side-bar/SideBar';
import MyHeader from '../header';
const { Header, Content } = Layout;

interface DefaultLayoutProps {
  children: ReactNode | string;
}
const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  return (
    <>
      <Layout className="main-body">
        <Header>
          <MyHeader />
        </Header>
        <Layout className="main-content">
          <SideBar />
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </>
  );
};

export default DefaultLayout;
