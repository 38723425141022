import { CheckboxOptionType, Radio } from 'antd';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
interface RadioButtonCustomProps extends RadioButtonProps {
  options: Array<OptionRadio>;
}
interface OptionRadio extends CheckboxOptionType {
  label: string;
  value: any;
}
const RadioButtonCustom = (props: RadioButtonCustomProps) => {
  return <Radio.Group {...props} className="radio-button-custom" />;
};
export default RadioButtonCustom;
