import { isMetaMaskInstalled } from 'blockchain/helper/utils';
import { LOGIN_STATUS } from 'common/constants';
import { userActions } from 'store/constants/user';

const initialState = {
  account: '',
  status: isMetaMaskInstalled()
    ? LOGIN_STATUS.NOT_LOGGED_IN
    : LOGIN_STATUS.METAMASK_UNAVAILABLE,
  accessToken: '',
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case userActions.SET_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }
    case userActions.SET_USER_SUCCESS: {
      return {
        ...state,
        account: action.payload?.account,
        // status : action.payload?.status,
        accessToken: action.payload?.accessToken,
      };
    }
    default: {
      return state;
    }
  }
};
