import { ROUTES } from 'common/constants';
import Homepage from 'pages/HomePage';
import Login from 'pages/Login';
// import User from 'pages/User';
import AdminManagement from 'pages/AdminManagement';
import PoolManagement from 'pages/PoolManagement';
// import CreatePool from 'pages/PoolManagement/CreatePool';
import PoolDetail from 'pages/PoolManagement/detail';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import CreatePool from 'pages/PoolManagement/CreatePool/CreatePool';

const RoutesContainer = () => {

  if (window.location.hostname === 'admin.pepperfi.io') {
    window.location.replace('https://finance-admin.astrone.io');
    return null;
  }

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path={ROUTES.DASHBOARD} component={Homepage} />
        {/* <Route exact path="" component={Homepage} /> */}
        <Route exact path={ROUTES.LOGIN} component={Login} />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN_MANAGEMENT}
          component={AdminManagement}
        />
        <PrivateRoute
          exact
          path={ROUTES.POOL_MANAGEMENT}
          component={PoolManagement}
        />
        <PrivateRoute exact path={ROUTES.CREATE_POOL} component={CreatePool} />
        <PrivateRoute exact path={ROUTES.POOL_DETAIL} component={PoolDetail} />
        <Redirect from="*" to={ROUTES.POOL_MANAGEMENT} />
      </Switch>
    </BrowserRouter>
  );
};

export default RoutesContainer;
