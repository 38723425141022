import { coin } from 'assets/themes/svgs';

interface NetworkInfo {
  [k: string]: any;
}
export const KEY_STORAGE = {
  USER: 'USER',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_ACCESS: 'REFRESH_ACCESS',
  SIGNATURE: 'SIGNATURE',
};

export const ROUTES = {
  DASHBOARD: '/',
  LOGIN: '/login',
  BLACKLIST: '/address-blacklists',
  PROJECTS: '/projects',
  CREATE_PROJECT: '/create',
  DETAIl: '/detail/:id',
  STAKING_POOL: '/staking-pools',
  CREATE_STAKING_POOL: '/staking-pools/create',
  STAKING_POOL_DETAIl: '/staking-pools/detail/:id',
  WHITELIST: '/address-whitelists',
  CREATE_WHITELIST: '/address-whitelists/create',
  WHITELIST_DETAIl: '/address-whitelists/detail/:id',
  PRESALE_NFT: '/presale-nfts',
  POOL_MANAGEMENT: '/pool-management',
  ADMIN_MANAGEMENT: '/admin-management',
  CREATE_POOL: '/pool/create',
  POOL_DETAIL: '/pool-detail/:id',
};
export const STATUS_POOL = {
  ENDED: 'ENDED',
  LIVE: 'LIVE',
};
export const BSC_CHAIN_ID = process.env
  .REACT_APP_BSC_CHAIN_ID_DECIMAL as string;
export const KCC_CHAIN_ID = process.env
  .REACT_APP_KCC_CHAIN_ID_DECIMAL as string;
export const LOGIN_STATUS = {
  METAMASK_UNAVAILABLE: 'METAMASK_UNAVAILABLE',
  LOGED_IN: 'LOGED_IN',
  NOT_ADMIN: 'NOT_ADMIN',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  CLOSE_CONNECT_METAMASK: 'CLOSE_CONNECT_METAMASK',
  CHANGE_NETWORK_METAMASK: 'CHANGE_NETWORK_METAMASK',
};

export const WARN_MESSAGES = {
  METAMASK_UNAVAILABLE: 'Please install or unlock Metamask Click me',
  NOT_ADMIN: 'There is no Admin account linked to this wallet address',
};
export const TEMPLATE_INNER_TEXT = {
  METAMASK_UNAVAILABLE: `<a href="https://metamask.io/download.html" target="_blank" styles>Click here</a>`,
};

export const NETWORK_BSC = {
  CHAIN_ID_HEX: process.env.REACT_APP_BSC_CHAIN_ID_HEX,
  CHAIN_ID_DECIMAL: process.env.REACT_APP_BSC_CHAIN_ID_DECIMAL,
  CHAIN_NAME: process.env.REACT_APP_BSC_CHAIN_NAME,
  RPC_URLS: process.env.REACT_APP_BSC_RPC_URLS,
  BLOCK_EXPLORER_URLS: process.env.REACT_APP_BSC_BLOCK_EXPLORER_URLS,
  NATIVE_CURRENCY: {
    NAME: process.env.REACT_APP_BSC_NATIVE_CURRENCY_NAME,
    SYMBOL: process.env.REACT_APP_BSC_NATIVE_CURRENCY_SYMBOL,
    DECIMAL: 18,
  },
};
export const NETWORK_KCC = {
  CHAIN_ID_HEX: process.env.REACT_APP_KCC_CHAIN_ID_HEX,
  CHAIN_ID_DECIMAL: process.env.REACT_APP_KCC_CHAIN_ID_DECIMAL,
  CHAIN_NAME: process.env.REACT_APP_KCC_CHAIN_NAME,
  RPC_URLS: process.env.REACT_APP_KCC_RPC_URLS,
  BLOCK_EXPLORER_URLS: process.env.REACT_APP_KCC_BLOCK_EXPLORER_URLS,
  NATIVE_CURRENCY: {
    NAME: process.env.REACT_APP_KCC_NATIVE_CURRENCY_NAME,
    SYMBOL: process.env.REACT_APP_KCC_NATIVE_CURRENCY_SYMBOL,
    DECIMAL: 18,
  },
};
export const APP_NETWORKS_SUPPORT: { [key: string]: NetworkInfo } = {
  [BSC_CHAIN_ID]: {
    chainId: NETWORK_BSC.CHAIN_ID_HEX,
    rpcUrls: [NETWORK_BSC.RPC_URLS],
    chainName: NETWORK_BSC.CHAIN_NAME,
    blockExplorerUrls: [NETWORK_BSC.BLOCK_EXPLORER_URLS],
    nativeCurrency: {
      name: NETWORK_BSC.NATIVE_CURRENCY.NAME,
      symbol: NETWORK_BSC.NATIVE_CURRENCY.SYMBOL,
      decimals: NETWORK_BSC.NATIVE_CURRENCY.DECIMAL,
    },
  },
  [KCC_CHAIN_ID]: {
    chainId: NETWORK_KCC.CHAIN_ID_HEX,
    rpcUrls: [NETWORK_KCC.RPC_URLS],
    chainName: NETWORK_KCC.CHAIN_NAME,
    blockExplorerUrls: [NETWORK_KCC.BLOCK_EXPLORER_URLS],
    nativeCurrency: {
      name: NETWORK_KCC.NATIVE_CURRENCY.NAME,
      symbol: NETWORK_KCC.NATIVE_CURRENCY.SYMBOL,
      decimals: NETWORK_KCC.NATIVE_CURRENCY.DECIMAL,
    },
  },
};

export const SWITCH_NETWORK: { [key: string]: NetworkInfo } = {
  [BSC_CHAIN_ID]: {
    chainId: NETWORK_BSC.CHAIN_ID_HEX,
  },
  [KCC_CHAIN_ID]: {
    chainId: NETWORK_KCC.CHAIN_ID_HEX,
  },
};
export const ALERT_TIME = 3000;
export const DELAY_TIME = 500;
export const LIMIT = 10;
export const ITEM_PER_PAGE = 10;
export const LIMIT_CHARACTER = 20;
export const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
export const exceptThisMinus = ['-'];

export const IMAGE_COIN: { [key: string]: any } = {
  ALGO: coin.coinAlgorandLogo,
  AVAX: coin.coinAvalancheLogo,
  BAT: coin.coinAttentionLogo,
  USD: coin.coinUsdLogo,
  BTC: coin.coinBitcoinLogo,
  BNB: coin.coinBNBLogo,
  ADA: coin.coinCardanoLogo,
  LINK: coin.coinChainLinkLogo,
  CRO: coin.coinCronosLogo,
  DASH: coin.coinDashLogo,
  DOG: coin.coinDogeLogo,
  ENJ: coin.coinEnjinLogo,
  ETC: coin.coinEthereumClassicLogo,
  ETH: coin.coinEthLogo,
  ENS: coin.coinEthEnsLogo,
  FTX: coin.coinFtxLogo,
  KCS: coin.coinKcsLogo,
  LITE: coin.coinLiteLogo,
  XMR: coin.coinMoneroLogo,
  DAI: coin.coinDaiLogo,
  NEAR: coin.coinNearLogo,
  ROSE: coin.coinRoseLogo,
  CAKE: coin.coinPancakeLogo,
  DOT: coin.coinPolkadotLogo,
  MATIC: coin.coinPolygonLogo,
  SHIBA: coin.coinShibaLogo,
  SOL: coin.coinSolanaLogo,
  STACK: coin.coinStackLogo,
  XLM: coin.coinStellarLogo,
  USDT: coin.coinUsdtLogo,
  TRX: coin.coinTronLogo,
  UNI: coin.coinUniswapLogo,
  LEO: coin.coinLeoLogo,
  USDC: coin.coinUsdcLogo,
  WBTC: coin.coinWbtcLogo,
  XRP: coin.coinXrpLogo,
  BTT: coin.coinXrpLogo,
};
