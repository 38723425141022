import { userActions } from 'store/constants/user';

export const setStatusAction = (status: string) => {
  return {
    type: userActions.SET_STATUS,
    payload: status,
  };
};
export const setUserSuccessAction = (user: {
  account?: string;
  accessToken?: any;
  status: string;
}) => {
  return {
    type: userActions.SET_USER_SUCCESS,
    payload: user,
  };
};
