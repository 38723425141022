import { Alert } from 'antd';
import TransactionError from './ErrorSystem';
import TransactionSuccess from './Success';
import './style.scss';

interface TransactionProps {
  type: 'error' | 'complete';
  children: any;
}

const Transaction: React.FC<TransactionProps> = ({ type, children }) => {
  const AlertTransaction = () => {
    switch (type) {
      case 'complete':
        return <TransactionSuccess>{children}</TransactionSuccess>;
      case 'error':
        return <TransactionError>{children}</TransactionError>;
      default:
        return <></>;
    }
  };
  return (
    <Alert
      className="message-alert"
      message={<AlertTransaction />}
      closable={true}
      data-type={type}
    />
  );
};

export default Transaction;
