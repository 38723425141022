import React from 'react';
import { Tabs } from 'antd';
import './style.scss';

interface TabCustomProps {
  items: Array<TabPane>;
}
interface TabPane {
  label: string;
  key: string;
  children: React.ReactNode;
}
const TabCustom: React.FC<TabCustomProps> = (props) => {
  const { items } = props;
  return (
    <div className="tabs-custom">
      <Tabs items={items} />
    </div>
  );
};

export default TabCustom;
