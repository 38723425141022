import { providers } from 'ethers';
import { injected } from './connectors';

export const getProvider = async () => {
  // TODO: find a better to detect what user connect by
  // hint: redux
  const isWc = 'walletconnect' in localStorage;

  const provider = await injected.getProvider();
  return new providers.Web3Provider(provider);
};
