import { getProvider } from './ether';
import { ethers } from 'ethers';
import _ from 'lodash';

const LOGIN_SIGN_MESSAGE = process.env.REACT_APP_LOGIN_SIGN_MESSAGE;

export const signWallet = (library: any) => {
  return signMessage(LOGIN_SIGN_MESSAGE, library);
};

export const isMetaMaskInstalled = () => {
  const isInstall = _.get(window, 'ethereum.isMetaMask', false);
  return isInstall;
};
export const signMessage = async (msg: any, library: any) => {
  if (!library) throw new Error('invalid params');

  const signer = library.getSigner();
  const address = await signer.getAddress();
  const isWc = 'walletconnect' in localStorage;
  if (isWc) {
    const provider: any = await getProvider();
    const signature = await provider.send('personal_sign', [
      ethers.utils.hexlify(ethers.utils.toUtf8Bytes(msg)),
      address.toLowerCase(),
    ]);
    return signature;
  }

  const signature = await library.provider.send('personal_sign', [
    ethers.utils.hexlify(ethers.utils.toUtf8Bytes(msg)),
    address.toLowerCase(),
  ]);
  return signature?.result;
};
