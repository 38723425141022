import { useDispatch, useSelector } from 'react-redux';
import {
  setStatusAction,
  setUserSuccessAction,
} from 'store/actions/userAction';
import { Button } from 'antd';
import { LOGIN_STATUS, ROUTES } from 'common/constants';
import icons from 'assets/themes/svgs';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { useEffect, useRef, useState } from 'react';

const START_COUNT = 5;
const SUFFIX_COUNT = 4;
const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const { account } = user;
  const start = account.slice(0, START_COUNT).trim();
  const end = account.slice(-SUFFIX_COUNT).trim();
  const history = useHistory();
  const [isOpenSubMenu, setDisplaySubMenu] = useState(false);
  const ref = useRef(null) as any;

  const logout = () => {
    const user = {
      account: '',
      accessToken: '',
      status: LOGIN_STATUS.NOT_LOGGED_IN,
    };
    localStorage.removeItem('accessToken');
    dispatch(setUserSuccessAction(user));
    dispatch(setStatusAction(LOGIN_STATUS.NOT_LOGGED_IN));
    history.push(ROUTES.LOGIN);
  };

  const handleClickAddress = () => {
    setDisplaySubMenu(!isOpenSubMenu);
  };
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      setDisplaySubMenu(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref]);
  return (
    <div className="header">
      <img src={icons.AstroneFiLogo} alt="logo" className="logo" />

      <div className="btn-connected" ref={ref}>
        <div className="cover-button">
          <Button onClick={handleClickAddress} className="header__dropdown">
            <img src={icons.Wallet} alt="wallet" className="wallet" />
            <button className="header__dropdown__btn">
              {`${start}...${end}`}
            </button>
            <img src={icons.IconDropDown} alt="IconDropDown" />
          </Button>
        </div>

        {isOpenSubMenu && (
          <div className="btn-disconnect" onClick={logout}>
            <img
              src={icons.Disconnect}
              alt="Disconnect"
              className="Disconnect"
            />
            <span>Disconnect</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
