import { Input, InputProps } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React from 'react';
import './style.scss';

interface InputCustomProps extends InputProps {
  forminput?: FormInstance<any> | null;
  onChangeCustom?: any;
}
const InputForm: React.FC<InputCustomProps> = (props) => {
  return (
    <Input
      {...props}
      className="input-base"
      onWheel={(event) => event.currentTarget.blur()}
    />
  );
};

export default InputForm;
