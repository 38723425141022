import { Button } from 'antd';
import React from 'react';
import './style.scss';
import { ButtonProps } from 'antd/lib/button';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

interface ButtonCustomProps extends ButtonProps {
  typeButton?:
    | 'btn-outline'
    | 'btn-secondary'
    | 'btn-primary'
    | 'btn-warning'
    | 'btn-outline-gray'
    | 'btn-linear';
  children: React.ReactNode;
  loading?: boolean;
}

const ButtonCustom: React.FC<ButtonCustomProps> = (props) => {
  const { typeButton = 'btn-primary', children, loading, ...rest } = props;
  let loadingColor = '#FFFFFF';
  if (typeButton === 'btn-outline') loadingColor = '#5833EB';

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 17, color: loadingColor, marginLeft: 10 }}
      spin
    />
  );
  return (
    <>
      <Button className={`buttonCustom ${typeButton}`} {...rest}>
        {children}
        {loading && <Spin indicator={antIcon} />}
      </Button>
    </>
  );
};
export default ButtonCustom;
