import { Col, Divider, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import icons from '../../assets/themes/svgs';
import Input from '../../components/common/Input';
import iconSuccess from 'assets/icons/success.svg';
import iconCopy from 'assets/icons/copy.svg';
import RadioButtonCustom from 'components/common/RadioButton';
import './style.scss';
import SwitchCustom from 'components/common/Switch';
import DynamicDetail from './DynamicDetail';
import userServices from 'services/user';
import { useHistory, useParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import {
  getContract,
  getContractByPoolChain,
  unpauseContract,
} from 'blockchain/helper/web3';
import useAuth from 'hooks/useAuth';
import {
  convertNumberTooBig,
  formatLiquid,
  numberWithCommasAutoDecimal,
  secondsToDay,
  trimMiddlePartAddress,
} from 'utils/function';
import { BSC_CHAIN_ID, KCC_CHAIN_ID, ROUTES } from 'common/constants';
import { toast } from 'react-toastify';
import LinearABI from './../../blockchain/abi/LinearAbi.json';
import AllocationABI from './../../blockchain/abi/AllocationAbi.json';
import { LoadingOutlined } from '@ant-design/icons';
import Web3 from 'web3';
import CopyToClipboard from 'react-copy-to-clipboard';

const PoolDetail = () => {
  const [details, setDetails] = useState<any>({});
  const history = useHistory();
  const [chain, setChain] = useState('');
  const [apr, setApr] = useState('');
  const [isDynamic, setIsDynamic] = useState<boolean>(false);
  const { id } = useParams() as { id: string };
  const { account } = useAuth();
  const [endBlock, setEndBlock] = useState(0);
  const [multiplierPeriod, setMultiplierPeriod] = useState(0);
  const [liquidity, setLiquidity] = useState(new BigNumber(0));
  const [isPause, setIsPause] = useState<boolean>(false);
  const windowObj = window as any;
  const { ethereum } = windowObj;
  const [loadingSwitch, setLoadingSwitch] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [copied, setCopied] = useState(false);

  const network = ethereum?.networkVersion;
  const web3 = new Web3();
  const pauseContract = async (address: string, addressPool: string) => {
    try {
      let contract = getContract(
        apr === 'FIXED' ? LinearABI : AllocationABI,
        addressPool
      );
      setLoadingSwitch(true);
      if (apr === 'FIXED') {
        return await contract?.methods.linearSetPool().send({
          from: address,
        });
      } else {
        return await contract?.methods.endPool().send({
          from: address,
        });
      }
    } catch (err) {
      setLoadingSwitch(false);
    }
  };

  const checkPauseContract = async (addressPool: string, chainPool: string) => {
    try {
      let contract = getContractByPoolChain(
        apr === 'FIXED' ? LinearABI : AllocationABI,
        addressPool,
        chainPool
      );
      if (apr === 'FIXED') {
        return await contract?.methods.isEnd().call();
      } else {
        return await contract?.methods.isEnd().call();
      }
    } catch (err) {
      setLoadingSwitch(false);
    }
  };

  const onChangeSwitch = (checked: boolean) => {
    const chainNumber = chain === 'KCC' ? KCC_CHAIN_ID : BSC_CHAIN_ID;
    if (network === chainNumber && details.address && chain) {
      checked
        ? unpauseContract(account ?? '', details.address)
        : pauseContract(account ?? '', details.address).then((value) => {
            checkPauseContract(details.address, chain).then((value) => {
              if (value) {
                setLoadingSwitch(false);
                toast.success('Stopped pool successfully');
                history.push(ROUTES.POOL_MANAGEMENT);
              }
              setLoadingSwitch(false);
            });
          });
    } else
      toast.error(
        `You are on a different network. Please switch to ${chain} to update pool.`
      );
  };

  useEffect(() => {
    getDetail();
  }, []);

  const isPauseContract = (data: any, chain: string) => {
    checkPauseContract(data.address, chain).then((value) => {
      setIsPause(value);
    });
  };

  const calculateLiquidity = (data: any) => {
    let liquidity = new BigNumber(0);
    let priceZero = false;
    data.tokenPools.map((item: any) => {
      if (new BigNumber(item.stakeToken.usdPrice).toNumber() === 0)
        priceZero = true;
      const convertTotalStake = new BigNumber(item.totalStake).multipliedBy(
        new BigNumber(10).pow(-item.stakeToken.decimal)
      );
      liquidity = BigNumber.sum(
        0,
        liquidity,
        new BigNumber(convertTotalStake).multipliedBy(item.stakeToken.usdPrice)
      );
      return liquidity;
    });
    if (priceZero) liquidity = new BigNumber(0);
    setLiquidity(liquidity);
  };

  const getDetail = async () => {
    try {
      const res = await userServices.getPoolDetail(id);

      if (res?.data) {
        setDetails(res.data);
        setChain(res.data?.chain);
        isPauseContract(res.data, res.data?.chain);
        setApr(res.data?.aprType);
        calculateLiquidity(res.data);
        setLoadingData(false);
        if (res.data?.aprType === 'DYNAMIC') {
          setIsDynamic(true);
          calculatedMultiplierPeriod(res.data?.startBlock, res.data?.endBlock);
        } else {
          setIsDynamic(false);
        }
      } else {
        setLoadingData(false);
        history.push(ROUTES.POOL_MANAGEMENT);
      }
    } catch (errors) {
      setLoadingData(false);
    }
  };

  const calculatedMultiplierPeriod = (startBlock: any, endBlock: any) => {
    const startBlockBigNumber = new BigNumber(startBlock);
    const multiplierPeriod = new BigNumber(endBlock)
      .minus(startBlockBigNumber)
      .multipliedBy(3)
      .div(new BigNumber(24).multipliedBy(3600))
      .toNumber();
    setMultiplierPeriod(multiplierPeriod);
  };

  const renderLabel = (title: string) => {
    return <span className="title font-500">{title}</span>;
  };

  const renderLabelRequired = (title: string) => {
    return (
      <>
        <span className="title font-500">{title}</span>
        <span className="text-required">*</span>
      </>
    );
  };

  const renderTitle = (title: string) => {
    return <span className="title-required font-600">{title}</span>;
  };
  const labelRequired = (title: string) => {
    return (
      <>
        <span className="title-required font-600">{title}</span>
        <span className="text-required">*</span>
      </>
    );
  };

  const formatPercent = (value: string) => {
    const percent = new BigNumber(value);
    if (percent.gt(new BigNumber(1000000))) {
      if (percent.isInteger()) {
        const result =
          value.substring(-1, 3) +
          '..' +
          value.substring(value.length - 2, value.length);
        return result;
      } else {
        const convertRes = parseFloat(value).toFixed(2);
        const result =
          convertRes.substring(-1, 3) +
          '..' +
          convertRes.substring(convertRes.length - 5, convertRes.length);
        return result;
      }
    }
    if (percent.isInteger()) {
      return value;
    } else {
      return parseFloat(value).toFixed(2);
    }
  };

  const loadingIcon = (
    <div className="detail-pool__flex-center">
      <LoadingOutlined style={{ fontSize: 40 }} spin />
    </div>
  );

  const copyAccountToClipBoard = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="detail-pool">
      {loadingData ? (
        loadingIcon
      ) : (
        <>
          <Row className="row1">
            <div className="row1__left">
              <img
                className="back-icon"
                src={icons.ArrowLeft}
                alt="icon-arrow-back"
                onClick={() => {
                  history.goBack();
                }}
              ></img>
              <div className="pool">
                <span className="pool-name font-700">
                  {details?.stakeTokens}
                </span>
                <div className="pool-address">
                  {trimMiddlePartAddress(details?.address || '0x')}{' '}
                  <CopyToClipboard
                    text={details?.address || '0x'}
                    onCopy={() => {
                      copyAccountToClipBoard();
                    }}
                  >
                    <img src={copied ? iconSuccess : iconCopy} alt="" />
                  </CopyToClipboard>
                  {copied && <span> Copied</span>}
                </div>
              </div>
            </div>

            <div className="row1__right">
              <span className="allow-staking">Allow staking</span>
              <SwitchCustom
                loading={loadingSwitch}
                checked={!isPause}
                disabled={isPause}
                onChange={onChangeSwitch}
              />
              <span className="stop-staking">Stop staking</span>
            </div>
          </Row>

          <Row className="content">
            <Col span={8} className="box basic-info">
              <div className="pool-index">
                <img src={icons.Frame1} alt="Frame1" />
                <div className="pool-index__info">
                  <span className="title font-500">Pool index</span>
                  <span className="value font-500">{details?.id}</span>
                </div>
              </div>
              <Divider className="divider-content" />
              <div className="liquidity">
                <img src={icons.Frame2} alt="Frame2" />
                <div className="liquidity__info">
                  <span className="title font-500">Liquidity ($)</span>
                  <Tooltip
                    placement="top"
                    title={numberWithCommasAutoDecimal(liquidity.toString())}
                  >
                    <span
                      className="value font-500"
                      style={{ width: 'fit-content' }}
                    >
                      {formatLiquid(liquidity)}
                    </span>
                  </Tooltip>
                </div>
              </div>
            </Col>

            <Col span={8} className="box basic-info">
              <div>
                <span className="title font-500">Set lock period (days)</span>
                <Input
                  type="text"
                  value={secondsToDay(details?.lockPeriod)}
                  name="lockPeriod"
                  disabled
                  style={{ marginTop: '5px' }}
                />
              </div>
              {isDynamic ? (
                <div style={{ marginTop: '20px' }}>
                  <span className="title font-500">
                    {renderLabelRequired('Reward per block')}
                  </span>
                  <Input
                    type="number"
                    value={web3.utils.fromWei(details?.rewardPerBlock, 'ether')}
                    name="lockPeriod"
                    disabled
                    style={{ marginTop: '5px' }}
                  />
                </div>
              ) : null}
            </Col>

            <Col span={8} className="box basic-info">
              <Row>
                <Col span={8}>
                  <div>
                    <span className="title font-500">
                      {renderLabelRequired('Chain')}
                    </span>
                    <RadioButtonCustom
                      name="chain"
                      options={[
                        { label: 'BSC', value: 'BSC' },
                        { label: 'KCC', value: 'KCC' },
                      ]}
                      value={chain}
                    ></RadioButtonCustom>
                  </div>
                  <div className="mt-lg">
                    <span className="title font-500">{renderLabel('APR')}</span>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <RadioButtonCustom
                        name="apr"
                        options={[
                          { label: 'Fixed', value: 'FIXED' },
                          { label: 'Dynamic', value: 'DYNAMIC' },
                        ]}
                        value={apr}
                      ></RadioButtonCustom>
                      <Tooltip
                        placement="top"
                        title={numberWithCommasAutoDecimal(details?.apr)}
                      >
                        <span className="apr-percent font-600">
                          {formatLiquid(details.apr)}%
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="content">
            <Row className="box">
              <Col span={12}>
                <span className="pair-token__box__title font-500">
                  {labelRequired('Stake token address')}
                </span>
              </Col>
              <Col span={12}>
                <span
                  className="pair-token__box__title font-500"
                  style={{ marginLeft: '20px' }}
                >
                  {labelRequired('Reward token address')}
                </span>
              </Col>
            </Row>
            {details?.tokenPools?.map((element: any, index: number) => {
              const {
                thumbLogo,
                address,
                tokenName,
                symbolTokenLp,
                symbol,
                decimal,
                token0,
                token1,
              } = element?.stakeToken;
              return (
                <Row key={index}>
                  <Col span={12} className="box">
                    <span className="pair-info font-400">
                      Pair {index + 1}:
                    </span>
                    <div className="input-stake">
                      <Input
                        name="stake_token_address"
                        type="text"
                        value={address}
                        disabled
                      />
                    </div>
                    <div className="token-info">
                      {token0 ? (
                        <div className="token-info__icon-token-lp">
                          <img
                            src={token0.thumbLogo || icons.LogoDefault}
                            alt="logo"
                            className="token-info__icon-token-lp__first"
                          />
                          <img
                            src={token1.thumbLogo || icons.LogoDefault}
                            alt="logo"
                            className="token-info__icon-token-lp__second"
                          />
                        </div>
                      ) : (
                        <img src={thumbLogo || icons.LogoDefault} alt="logo" />
                      )}
                      <div className="token-container">
                        <div className="token-container__name">
                          <div className="text-logo">
                            <div className="name-token font-500">Token</div>
                            <div className="value-token font-700">
                              {tokenName}
                            </div>
                          </div>
                        </div>

                        <div className="token-container__name">
                          <div className="text-logo">
                            <div className="name-token font-500">
                              Token symbol
                            </div>
                            <div className="value-token font-700">
                              {symbolTokenLp !== '' ? symbolTokenLp : symbol}
                            </div>
                          </div>
                        </div>
                        <div
                          className="token-container__name"
                          style={{ borderRight: 'none' }}
                        >
                          <div className="text-logo">
                            <div className="name-token font-500">
                              Token Decimals
                            </div>
                            <div className="value-token font-700">
                              {decimal}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={12} className="box">
                    <span
                      className="pair-info font-400"
                      style={{ visibility: 'hidden' }}
                    >
                      .
                    </span>
                    <div className="input-stake ">
                      <Input
                        name="stake_token_address"
                        type="text"
                        value={element?.rewardToken?.address}
                        disabled
                      />
                    </div>
                    <div className="token-info">
                      {element.rewardToken.token0 ? (
                        <div className="token-info__icon-token-lp">
                          <img
                            src={
                              element.rewardToken.token0.thumbLogo ||
                              icons.LogoDefault
                            }
                            alt="logo"
                            className="token-info__icon-token-lp__first"
                          />
                          <img
                            src={
                              element.rewardToken.token1.thumbLogo ||
                              icons.LogoDefault
                            }
                            alt="logo"
                            className="token-info__icon-token-lp__second"
                          />
                        </div>
                      ) : (
                        <img
                          src={
                            element.rewardToken.thumbLogo || icons.LogoDefault
                          }
                          alt="logo"
                        />
                      )}
                      <div className="token-container">
                        <div className="token-container__name">
                          <div className="text-logo">
                            <div className="name-token font-500">Token</div>
                            <div className="value-token font-700">
                              {element.rewardToken.tokenName}
                            </div>
                          </div>
                        </div>

                        <div className="token-container__name">
                          <div className="text-logo">
                            <div className="name-token font-500">
                              Token symbol
                            </div>
                            <div className="value-token font-700">
                              {element?.rewardToken.symbolTokenLp !== ''
                                ? element?.rewardToken.symbolTokenLp
                                : element?.rewardToken.symbol}
                            </div>
                          </div>
                        </div>
                        <div
                          className="token-container__name"
                          style={{ borderRight: 'none' }}
                        >
                          <div className="text-logo">
                            <div className="name-token font-500">
                              Token Decimals
                            </div>
                            <div className="value-token font-700">
                              {element?.rewardToken?.decimal}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
            {details?.tokenPools?.length > 1 ? (
              <Col span={24} className="box-row">
                <span
                  className="title font-600"
                  style={{ marginRight: '24px' }}
                >
                  {labelRequired('Staking ratio between tokens')}
                </span>
                {details.tokenPools.map((item: any, index: any) => {
                  const { id, symbol, symbolTokenLp } = item.stakeToken;
                  return (
                    <div key={index} className="box-ratio">
                      <Input type="text" value={item.rate} disabled />
                      <span className="symbol">
                        {symbolTokenLp !== '' ? symbolTokenLp : symbol}
                      </span>
                      <span className="two-dot">:</span>
                    </div>
                  );
                })}
              </Col>
            ) : (
              ''
            )}
          </div>
          {isDynamic ? (
            <div className="content">
              <DynamicDetail
                multiplierPeriod={multiplierPeriod}
                pool={details}
                renderLabelRequired={renderLabelRequired}
                renderLabel={renderLabel}
                renderTitle={renderTitle}
                endBlock={endBlock}
                chain={chain}
              />
            </div>
          ) : (
            <div className="content">
              <Row>
                <Col span={12} className="box">
                  <span className="title font-500">
                    {labelRequired('Staking limit')}
                  </span>
                  <div className="input-stake ">
                    <Input
                      name="stake_token_address"
                      type="text"
                      value={numberWithCommasAutoDecimal(
                        convertNumberTooBig(details?.stakingLimit, 0)
                      )}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default PoolDetail;
