import './style.scss';
import { useSelector } from 'react-redux';
import Button from 'components/common/Button';
import { injected } from 'blockchain/helper/connectors';
import images from 'assets/images';
import {
  LOGIN_STATUS,
  ROUTES,
  TEMPLATE_INNER_TEXT,
  WARN_MESSAGES,
} from 'common/constants';
import useAuth from 'hooks/useAuth';
import ModalChangeNetwork from './ModalChangeNetwork';
import { Redirect, Route } from 'react-router-dom';
import icons from 'assets/themes/svgs';

const Login = () => {
  const { login, loading, isNetworkPopup, setIsNetworkPopup, changeNetwork } =
    useAuth();
  const user = useSelector((state: any) => state.user);
  const { status, accessToken } = user;

  const version = process.env.REACT_APP_VERSION;

  if (accessToken) {
    return (
      <Route
        render={(props) => (
          <Redirect
            to={{
              pathname: ROUTES.POOL_MANAGEMENT,
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  const handleClick = () => {
    login(injected);
  };
  const createMarkup = () => {
    const textShow = WARN_MESSAGES.METAMASK_UNAVAILABLE.replace(
      'Click me',
      TEMPLATE_INNER_TEXT.METAMASK_UNAVAILABLE
    );
    return { __html: textShow };
  };

  const renderWarning = () => {
    if (
      status === LOGIN_STATUS.CLOSE_CONNECT_METAMASK ||
      status === LOGIN_STATUS.METAMASK_UNAVAILABLE
    ) {
      return (
        <div className="login-page__container__lock font-400">
          <div
            className="txt-warning font-400"
            style={{ marginBottom: '2px' }}
            dangerouslySetInnerHTML={createMarkup()}
          ></div>
        </div>
      );
    }
    if (status === LOGIN_STATUS.NOT_ADMIN) {
      return (
        <span className="txt-warning font-400">{WARN_MESSAGES.NOT_ADMIN}</span>
      );
    }
    if (status === LOGIN_STATUS.CHANGE_NETWORK_METAMASK) {
      return (
        <div className="login-page__container__lock">
          <span
            className="txt-warning font-400"
            style={{ marginBottom: '2px' }}
          >
            Network not supported.
          </span>
          <button className="login-page__container__lock__btn-clickMe">
            <span className="txt-warning link font-400">
              Please switch to BSC or KCC to continue
            </span>
          </button>
        </div>
      );
    }
  };

  const handleCancel = () => {
    setIsNetworkPopup(false);
  };

  return (
    <div className="login-page" data-version={version}>
      <div className="login-page__logo">
        <img src={icons.AstroneFiLogo} alt="astrone"></img>
      </div>

      <div className="login-page__container">
        <img
          src={images.FireWork}
          alt="firework"
          className="login-page__container__firework"
        />

        <span className="login-page__container__txt-content">
          Welcome to AstroneFi
        </span>

        <span className="login-page__container__txt-notification font-400">
          Please connect wallet to continue
        </span>

        {status === LOGIN_STATUS.CHANGE_NETWORK_METAMASK ? (
          <Button
            typeButton="btn-outline-gray"
            onClick={() => {
              setIsNetworkPopup(!isNetworkPopup);
            }}
            style={{
              borderRadius: '30px',
              marginBottom: `${
                status === LOGIN_STATUS.NOT_LOGGED_IN ? '16px' : '0px'
              }`,
              minWidth: '260px',
              minHeight: '56px',
            }}
            loading={loading}
          >
            Change network
          </Button>
        ) : (
          <Button
            typeButton="btn-primary"
            disabled={
              status === LOGIN_STATUS.METAMASK_UNAVAILABLE ? true : false
            }
            onClick={() => handleClick()}
            style={{
              borderRadius: '30px',
              marginBottom: `${
                status === LOGIN_STATUS.NOT_LOGGED_IN ? '16x' : '0px'
              }`,
              minWidth: '260px',
              minHeight: '56px',
            }}
            loading={loading}
          >
            {loading ? 'Signing in' : 'Sign in'}
          </Button>
        )}
        {renderWarning()}
      </div>
      <ModalChangeNetwork
        isNetworkPopUp={isNetworkPopup}
        handleCancel={handleCancel}
        changeNetwork={changeNetwork}
      />
    </div>
  );
};

export default Login;
