import ButtonCustom from 'components/common/Button';
import SwitchCustom from 'components/common/Switch';
import TabCustom from 'components/common/Tab';
import RadioButtonCustom from 'components/common/RadioButton';

const items = [
  {
    label: 'phuc',
    key: 'phuc1',
    children: <SwitchCustom />,
  },
  {
    label: 'phuc2',
    key: 'phuc2',
    children: <ButtonCustom>test</ButtonCustom>,
  },
];
const options = [
  { label: 'Apple', value: 'Apple' },
  { label: 'Pear', value: 'Pear' },
];

const Homepage = () => {
  return (
    <>
      <ButtonCustom typeButton="btn-primary" loading={true}>
        Default
      </ButtonCustom>
      <ButtonCustom typeButton="btn-secondary" loading={false}>
        Default
      </ButtonCustom>
      <ButtonCustom typeButton="btn-outline" loading={false}>
        Default
      </ButtonCustom>

      <SwitchCustom />

      <TabCustom items={items} />

      <RadioButtonCustom options={options} />
    </>
  );
};

export default Homepage;
