import { Button, Col, Form, FormInstance, Row } from 'antd';
import icons from 'assets/themes/svgs';
import React, { useState } from 'react';
import InputToken from 'components/common/InputToken';
import IndexRatio from 'components/common/InputRatio';
import './style.scss';

interface PairTokenProps {
  form: FormInstance;
  chain?: string;
  labelRequired: (title: string) => JSX.Element;
  preventDecimal?: (e: any) => void;
}

const PairToken: React.FC<PairTokenProps> = (props) => {
  const { form, chain, preventDecimal, labelRequired } = props;
  const [symbolToken, setSymbolToken] = useState<String[]>([]);

  return (
    <Form.List
      name="fields"
      initialValue={[{ name: 0, key: 0, isListField: true, fieldKey: 0 }]}
    >
      {(fields, { add, remove }) => {
        return (
          <Row className="pair-token">
            <Col span={24} className="pair-token__box">
              <Row>
                <Col span={12}>
                  <span className="pair-token__box__title font-600">
                    {labelRequired('Stake token address')}
                  </span>
                </Col>

                <Col span={12}>
                  <span className="pair-token__box__title font-600">
                    {labelRequired('Reward token address')}
                  </span>
                </Col>
              </Row>
              {fields.map((field, index) => {
                return (
                  <Row className="pair-token" gutter={[8, 8]} key={field.key}>
                    <Col span={12}>
                      <div className="pair-token__input-token">
                        <span className="pair-token__input-token__pair-info font-700">
                          {`Pair ${index + 1}`}:
                        </span>
                        <InputToken
                          chain={chain}
                          form={form}
                          fields={fields}
                          nameForm={[index, '_stakeToken']}
                          symbolToken={symbolToken}
                          setSymbolToken={setSymbolToken}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="pair-token__input-token flex-end">
                        <Button
                          className="pair-token__input-token__btn-delete"
                          onClick={() => remove(field.name)}
                          disabled={fields?.length === 1}
                        >
                          <img src={icons.UnionRed} alt="delete" />
                        </Button>
                        <InputToken
                          chain={chain}
                          form={form}
                          nameForm={[index, '_saleToken']}
                          symbolToken={symbolToken}
                          setSymbolToken={setSymbolToken}
                        />
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </Col>
            <Button
              className="btn-add-token"
              onClick={() => add()}
              disabled={fields.length >= 3 ? true : false}
            >
              <img src={icons.AddToken} alt="add token"></img>
            </Button>

            {fields.length > 1 && (
              <Col span={24} className="box-row">
                <span className="title font-600">
                  {labelRequired('Staking ratio between tokens')}
                </span>
                {fields.map((field, index) => {
                  return (
                    <div key={index} className="box-row__item">
                      <IndexRatio
                        form={form}
                        nameSymbol={[index, '_stakeToken']}
                        nameForm={[index, '_stakedTokenRate']}
                        index={index}
                        preventDecimal={preventDecimal}
                      />
                    </div>
                  );
                })}
              </Col>
            )}
          </Row>
        );
      }}
    </Form.List>
  );
};

export default PairToken;
