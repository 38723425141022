import { Col, Row } from 'antd';
import { NETWORK_BSC, NETWORK_KCC } from 'common/constants';
import InputBase from 'components/common/Input';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { numberWithCommasAutoDecimal, secondsToDay } from 'utils/function';
import './style.scss';

interface DynamicSettingProps {
  renderLabelRequired: any;
  renderLabel: any;
  pool?: any;
  endBlock?: number;
  multiplierPeriod: number;
  chain?: string;
  renderTitle: (title: string) => JSX.Element;
}
const DynamicDetail: React.FC<DynamicSettingProps> = (props) => {
  const {
    renderLabelRequired,
    renderLabel,
    pool,
    multiplierPeriod,
    chain,
    renderTitle,
  } = props;
  const [currentBlock, setCurrentBlock] = useState(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      const provider = new ethers.providers.JsonRpcProvider(
        chain === 'BSC' ? NETWORK_BSC.RPC_URLS : NETWORK_KCC.RPC_URLS
      );
      const blockNumber = await provider.getBlockNumber();
      setCurrentBlock(blockNumber);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Row className="container" gutter={[16, 0]}>
      <div className="container__header">
        <span className="title font-500">
          {renderTitle('Multiplier setting')}
        </span>
        <span className="warning font-400">
          *After the end block, the reward will be calculated with a multiplier
          of 1
        </span>
      </div>
      <Col span={8}>
        <span className="title font-500">
          {renderLabelRequired('Multiplier')}
        </span>
        <InputBase
          name="_bonusMultiplier"
          type="text"
          placeholder="Enter"
          style={{ marginTop: '5px' }}
          value={numberWithCommasAutoDecimal(pool?.multiplier)}
          disabled
        />
        <span className="title font-500" style={{ marginTop: '20px' }}>
          {renderLabelRequired('Multiplier Period (in days)')}
        </span>
        <InputBase
          name="_bonusEndBlock"
          type="text"
          placeholder="Enter"
          style={{ marginTop: '5px' }}
          value={numberWithCommasAutoDecimal(multiplierPeriod.toString())}
          disabled
        />
      </Col>

      <Col span={8} style={{ display: 'flex', alignItems: 'end' }}>
        <div style={{ width: '100%' }}>
          <span className="title font-500" style={{ marginTop: '20px' }}>
            {renderLabelRequired('Start block')}
          </span>
          <InputBase
            name="_startBlock"
            type="number"
            placeholder="Enter"
            style={{ marginTop: '5px' }}
            value={pool?.startBlock}
            disabled
          />
        </div>
      </Col>

      <Col span={8}>
        <span className="title font-500">{renderLabel('Current block')}</span>
        <InputBase
          type="text"
          value={currentBlock}
          style={{ marginTop: '5px' }}
          disabled
          name="_currentBlock"
        />

        <span className="title font-500" style={{ marginTop: '20px' }}>
          {renderLabel('End block')}
        </span>
        <InputBase
          type="text"
          value={pool?.endBlock}
          style={{ marginTop: '5px' }}
          disabled
          name="_endBlock"
        />
      </Col>
    </Row>
  );
};

export default DynamicDetail;
