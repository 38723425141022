import { blackListReducer } from './blacklist';
import { combineReducers } from 'redux';
import { userReducer } from './user';

const rootReducer = combineReducers({
  blackList: blackListReducer,
  user: userReducer,
});

export default rootReducer;
