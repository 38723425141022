import { ReactNode } from 'react';
import images from 'assets/images';
import './styles.scss';

interface TransactionErrorProps {
  children: ReactNode;
}

const TransactionError: React.FC<TransactionErrorProps> = (props) => {
  const { children } = props;
  return (
    <div className="message-err">
      <img src={images.MessErr} alt="Transaction Error"></img>
      <div className="message-err__message-content">{children}</div>
    </div>
  );
};
export default TransactionError;
