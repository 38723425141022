import { Col, Form, Row } from 'antd';
import { exceptThisSymbols } from 'common/constants';
import InputForm from 'components/common/InputForm';
import _ from 'lodash';
import React from 'react';
import { preventCharacterLimit, removeZeroNumber } from 'utils/function';

interface StakingLimitProps {
  form: any;
  labelRequired: (title: string) => JSX.Element;
}
const StakingLimit: React.FC<StakingLimitProps> = (props) => {
  const { form, labelRequired } = props;

  return (
    <Row>
      <Col span={12} className="box">
        <span className="title font-500">{labelRequired('Staking limit')}</span>
        <div className="input-stake" style={{ marginTop: '20px' }}>
          <Form.Item
            name="_cap"
            style={{ margin: 0, width: '100%' }}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error(`Please fill in this field`)
                    );
                  } else {
                    if (value && value?.toString() === '0') {
                      return Promise.reject(
                        new Error('Staking limit must be greater than 0')
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputForm
              onBlur={(e) => removeZeroNumber(e, form)}
              onChange={(e) => preventCharacterLimit(e, form)}
              onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              }
              onPaste={(e) => {
                const text = e.clipboardData.getData('text');
                const arrayText = text.split('');
                arrayText?.map((item) => {
                  if (_.includes(exceptThisSymbols, item)) {
                    e.preventDefault();
                  }
                });
              }}
              name="_cap"
              forminput={form}
              type="number"
              placeholder="Enter"
            />
          </Form.Item>
        </div>
      </Col>
    </Row>
  );
};

export default StakingLimit;
