import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import '../DefaultLayout.scss';
import icons from 'assets/themes/svgs';

const { Sider } = Layout;

const SideBar = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  return (
    <Sider theme="light" className="menu-side-bar">
      <Menu
        theme="light"
        mode="inline"
        className="Menu ide"
        selectedKeys={[window.location.pathname, '/pool/create']}
        items={[
          {
            key: '/pool/create',
            icon: <img src={icons.IconPoolManagement} alt="pool" />,
            label: <NavLink to="/pool-management">Pool Management</NavLink>,
          },
        ]}
      />
    </Sider>
  );
};

export default SideBar;
