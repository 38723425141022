import React from 'react';
import icons from 'assets/themes/svgs';
import { IMAGE_COIN } from 'common/constants';
import './style.scss';
interface TokenInfoProps {
  name?: string;
  symbol?: string;
  decimal?: string;
  token0Symbol?: string;
  token1Symbol?: string;
}
const TokenInfo: React.FC<TokenInfoProps> = (props) => {
  const { name, symbol, decimal, token0Symbol, token1Symbol } = props;

  return (
    <div className="token-info">
      {token0Symbol ? (
        <div className="token-info__icon-token-lp">
          <img
            src={
              (token0Symbol && IMAGE_COIN[token0Symbol]) ?? icons.LogoDefault
            }
            alt="logo"
            className="token-info__icon-token-lp__first"
          />
          <img
            src={
              (token1Symbol && IMAGE_COIN[token1Symbol]) ?? icons.LogoDefault
            }
            alt="logo"
            className="token-info__icon-token-lp__second"
          />
        </div>
      ) : (
        <img
          src={(symbol && IMAGE_COIN[symbol]) ?? icons.LogoDefault}
          alt="logo"
          className="token-info__icon-token"
        />
      )}

      <div className="token-container">
        <div className="token-container__name">
          <div className="text-logo">
            <div className="name-token font-500">Token</div>
            <div className="value-token font-700">{name}</div>
          </div>
        </div>

        <div className="token-container__name">
          <div className="text-logo">
            <div className="name-token font-500">Token symbol</div>
            <div className="value-token font-700">
              {token0Symbol && token1Symbol
                ? token0Symbol + '/' + token1Symbol
                : symbol}
            </div>
          </div>
        </div>
        <div className="token-container__name" style={{ borderRight: 'none' }}>
          <div className="text-logo">
            <div className="name-token font-500">Token decimals</div>
            <div className="value-token font-700">{decimal}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenInfo;
