import { Form, FormInstance } from 'antd';
import BigNumber from 'bignumber.js';
import { LIMIT_CHARACTER } from 'common/constants';
import React from 'react';
import InputForm from '../InputForm';
import './style.scss';

interface IndexRatioProps {
  form: FormInstance;
  index?: number;
  nameForm: string | any;
  nameSymbol: string | any;
  preventDecimal?: (e: any) => void;
}

const IndexRatio: React.FC<IndexRatioProps> = (props) => {
  const { form, nameForm, preventDecimal, nameSymbol, index } = props;
  const removeZero = (e: any, form: FormInstance) => {
    const { value } = e.target;
    const { fields } = form?.getFieldsValue();

    if (fields?.[nameForm[0]] && value) {
      fields[nameForm[0]] = {
        ...fields[nameForm[0]],
        _stakedTokenRate: new BigNumber(value).toString(),
      };
    }
    form?.setFieldsValue({ fields });
  };

  const preventCharacter = (e: any) => {
    const { value } = e.target;
    const { fields } = form?.getFieldsValue();
    if (fields?.[nameForm[0]] && value.length > 1) {
      fields[nameForm[0]] = {
        ...fields[nameForm[0]],
        _stakedTokenRate: value.slice(0, LIMIT_CHARACTER),
      };
    }
    form?.setFieldsValue({ fields });
  };

  return (
    <div className="input-ratio">
      <Form.Item className="input-ratio__form" shouldUpdate={() => true}>
        {() => {
          const { fields } = form?.getFieldsValue();
          const symbol = fields?.[nameSymbol[0]]?.[nameSymbol[1] + 'symbol'];
          return (
            <div className="input-ratio__container">
              <Form.Item
                name={nameForm}
                className="input-ratio__container__form-item"
                style={{ margin: 0, marginLeft: '24px' }}
                rules={[
                  { required: true, message: 'Please fill in this field' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value?.toString() === '0') {
                        return Promise.reject(
                          new Error('Please enter a value greater than 0')
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputForm
                  forminput={form}
                  type="number"
                  name={`fields_${nameForm[0]}_${nameForm[1]}`}
                  placeholder="Enter"
                  style={{ marginTop: '5px' }}
                  maxLength={20}
                  onKeyDown={preventDecimal}
                  onChange={preventCharacter}
                  onBlur={(e) => removeZero(e, form)}
                />
              </Form.Item>
              <span className="box-row__tittle font-300">
                {symbol !== '' ? symbol : 'Token Symbol'}
              </span>
              {index === fields.length - 1 ? (
                <></>
              ) : (
                <span className="font-300">:</span>
              )}
            </div>
          );
        }}
      </Form.Item>
    </div>
  );
};

export default IndexRatio;
