import Logo from '../images/logo.png';
import Astrone from '../images/ASTRONE.png';
import MessErr from '../images/MessError.png';
import FireWork from '../images/firework.png';
import BSC_LOGO from '../images/BSC-logo.png';
import KCC_LOGO from '../images/KCC-logo.png';
import Percent from '../images/percent.png';
import TransactionComplete from '../images/transactionComplete.png';

const images = {
  Logo,
  Astrone,
  MessErr,
  FireWork,
  BSC_LOGO,
  KCC_LOGO,
  Percent,
  TransactionComplete,
};

export default images;
