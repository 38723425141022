import { FormInstance } from 'antd';
import BigNumber from 'bignumber.js';
import { LIMIT_CHARACTER } from 'common/constants';
const commaNumber = require('comma-number');

export const removeZeroNumber = (e: any, form: FormInstance) => {
  const { value, name } = e.target;
  if (value) {
    const newValue = new BigNumber(value).toFixed().toString();
    form.setFieldsValue({ [name]: newValue });
  }
};

export const preventCharacterLimit = (
  e: React.ChangeEvent<HTMLInputElement>,
  form: FormInstance,
  limit = LIMIT_CHARACTER
) => {
  const { name, value } = e.target;
  form.setFieldValue([name], value.slice(0, limit));
};

export const preventAPRCharacterLimit = (
  e: React.ChangeEvent<HTMLInputElement>,
  form: FormInstance
) => {
  const { name, value } = e.target;
  const arrayValueNum = value.toString().split('.');
  if (!arrayValueNum[1]) {
    form.setFieldValue([name], value.slice(0, 10));
  } else {
    const integer = arrayValueNum[0].slice(0, 10);
    const decimal = arrayValueNum[1].slice(0, 2);
    const convertValue = integer.concat('.', decimal);
    form.setFieldValue([name], convertValue);
  }
};
export const convertNumberTooBig = (number: any, decimal = 2) => {
  const value = new BigNumber(number)
    .multipliedBy(new BigNumber(10).pow(-18))
    .toFixed(decimal);
  return value;
};

// export const secondsToDhms = (seconds: number) => {
//   seconds = Number(seconds);
//   var d = Math.floor(seconds / (3600 * 24));
//   var h = Math.floor(seconds % (3600 * 24) / 3600);
//   var m = Math.floor(seconds % 3600 / 60);
//   var s = Math.floor(seconds % 60);

//   var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
//   var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
//   var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
//   var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
//   return dDisplay + hDisplay + mDisplay + sDisplay;
// }

export const secondsToDay = (seconds: number) => {
  seconds = Number(seconds);
  var d = new BigNumber(seconds)
    .div(new BigNumber(3600).times(new BigNumber(24)))
    .toString();
  return d;
};

export const subDecimalNumber = (number: any) => {
  let SUB = 0;
  if (Math.abs(number) < 1.0) {
    // eslint-disable-next-line radix
    const e = parseInt(number.toString().split('e-')[1]);
    SUB = e;
    if (e) {
      number *= Math.pow(10, e - 1);
      number = `0.${new Array(e).join('0')}${number.toString().substring(2)}`;
    }
  } else {
    let e = parseInt(number.toString().split('+')[1]);
    SUB = e;
    if (e > 20) {
      e -= 20;
      number /= Math.pow(10, e);
      number += new Array(e + 1).join('0');
    }
  }
  return Number(number).toFixed(SUB);
};
export const formatLiquid = (value: any, decimals: number = 2) => {
  const newValue = new BigNumber(value);
  if (newValue.isEqualTo(0)) return '0';

  if (newValue.lt(new BigNumber(0.01))) {
    return '<0.01';
  }
  if (newValue.gt(new BigNumber(1000000))) {
    return nFormatterMillion(newValue.toString(), decimals);
  } else {
    return numberWithCommas(newValue.toString(), decimals);
  }
};
export function format(num: string) {
  const splittedStrs = num.split('.');
  if (splittedStrs[1] !== undefined) {
    return (
      splittedStrs[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      '.' +
      splittedStrs[1]
    );
  }
  return splittedStrs[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberWithCommas(x: string = '', decimals: number = 2) {
  x = typeof x === 'string' ? x : (x as number).toFixed();
  return commaNumber(
    new BigNumber(x).toFixed(decimals, 1).replace(/\.?0+$/, ''),
    ',',
    '.'
  );
}

export function numberWithCommasAutoDecimal(x: string = '') {
  x = typeof x === 'string' ? x : (x as number).toFixed();
  return commaNumber(new BigNumber(x).toFixed(), ',', '.');
}

export const nFormatterMillion = (number: string, decimals: number = 2) => {
  if (!number) {
    return '0';
  }
  const SI = [
    { value: 1, symbol: '' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const num = new BigNumber(number);

  const item = SI.slice()
    .reverse()
    .find((item) => {
      return num.gte(item.value);
    });
  if (!item) return '0';
  const arrayNum = num.toString().split('.');

  const sliceNumber =
    Number(arrayNum[0].length) - item.value.toString().length + 1;
  return (
    `${numberWithCommasAutoDecimal(
      arrayNum[0].slice(0, sliceNumber)
    )}.${arrayNum[0].slice(sliceNumber, sliceNumber + 2)}` + item.symbol
  );
};

export const trimMiddlePartAddress = (
  tx: string,
  first = 5,
  last = 4
): string =>
  `${tx.substring(0, first)}...${tx.substring(tx.length - last, tx.length)}`;
