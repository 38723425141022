import React from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { ITEM_PER_PAGE } from 'common/constants';
import icons from 'assets/themes/svgs';
interface TableStakingProps {
  data: any;
  total?: number;
  page?: number;
  columns: any;
  routeDetail?: string;
  handleChangePage: (currentPage: number) => void;
}

const TableStaking: React.FC<TableStakingProps> = (props) => {
  const { data, total, page, handleChangePage, columns, routeDetail } = props;
  let locale = {
    emptyText: (
      <div className="flex">
        <img src={icons.SearchNoData} alt="SearchNoData" />
        <span className="text-no-record">No records</span>
      </div>
    ),
  };
  const history = useHistory();

  return (
    <div className="table-pool">
      <Table
        rowKey="index"
        columns={columns}
        dataSource={data}
        onRow={(record: any, index?: number) => ({
          style: { cursor: 'pointer' },
          onClick: () =>
            routeDetail &&
            history.push(routeDetail.replace(':id', `${record.id}`)),
          // onClick: () => history.push(routeDetail+`${record.index}`),
          key: { index },
        })}
        pagination={{
          total: total,
          current: page,
          pageSize: ITEM_PER_PAGE,
          showSizeChanger: false,
          position: total && total > ITEM_PER_PAGE ? ['bottomRight'] : [],
        }}
        onChange={(pagination, filters, sorter) => {
          pagination.current && handleChangePage(pagination.current);
        }}
        locale={locale}
      />
    </div>
  );
};

export default TableStaking;
