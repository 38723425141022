import { useEagerConnect } from 'hooks/useEagerConnect';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useWeb3React } from '@web3-react/core';
import { LOGIN_STATUS } from 'common/constants';
import {
  setStatusAction,
  setUserSuccessAction,
} from 'store/actions/userAction';

declare var window: any;

const CHAIN_ID_BSC = process.env.REACT_APP_BSC_CHAIN_ID_HEX;
const CHAIN_ID_KCC = process.env.REACT_APP_KCC_CHAIN_ID_HEX;

const AppContainer = (props: any) => {
  // handle web3
  const dispatch = useDispatch();
  const { connector, library, account, active, deactivate } = useWeb3React();
  const triedEager = useEagerConnect();
  const isConnected =
    triedEager && Boolean(connector) && Boolean(library?.provider);

  const user = {
    account: '',
    accessToken: '',
    status: LOGIN_STATUS.NOT_LOGGED_IN,
  };

  useEffect(() => {
    // check is metamask installed
    if (
      typeof window.ethereum !== 'undefined' ||
      typeof window.web3 !== 'undefined'
    ) {
      window.ethereum._metamask.isUnlocked().then((res: any) => {
        if (!res) {
          localStorage.removeItem('accessToken');
          dispatch(setUserSuccessAction(user));
          dispatch(setStatusAction(LOGIN_STATUS.NOT_LOGGED_IN));
        }
      });
    }

    if (!isConnected) return;

    const logOut = ([account]: any) => {
      const user = {
        account: '',
        accessToken: '',
        status: LOGIN_STATUS.NOT_LOGGED_IN,
      };
      localStorage.removeItem('accessToken');
      deactivate();
      dispatch(setUserSuccessAction(user));
      dispatch(setStatusAction(LOGIN_STATUS.NOT_LOGGED_IN));
    };

    window.ethereum.on('accountsChanged', logOut);

    return () => {
      window.ethereum?.removeListener('accountsChanged', logOut); // need func reference to remove correctly
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, active]);

  useEffect(() => {
    if (!window.ethereum) return;
    const onChangeNetwork = (chainId: string) => {
      if (
        active &&
        Number(CHAIN_ID_BSC) !== Number(chainId) &&
        Number(CHAIN_ID_KCC) !== Number(chainId)
      ) {
        const currentUser = {
          ...user,
          status: LOGIN_STATUS.CHANGE_NETWORK_METAMASK,
        };
        localStorage.removeItem('accessToken');
        dispatch(setUserSuccessAction(currentUser));
        dispatch(setStatusAction(LOGIN_STATUS.CHANGE_NETWORK_METAMASK));
      }

      if (
        Number(CHAIN_ID_BSC) === Number(chainId) ||
        Number(CHAIN_ID_KCC) === Number(chainId)
      ) {
        dispatch(setStatusAction(LOGIN_STATUS.NOT_LOGGED_IN));
      }
    };
    window.ethereum.on('chainChanged', onChangeNetwork);

    return () => {
      if (window.ethereum.removeListener) {
        window.ethereum.removeListener('chainChanged', onChangeNetwork); // need func reference to remove correctly
      }
    };
  }, [account]);

  return <div style={{ width: '100%', height: '100%' }}>{props.children}</div>;
};

export default AppContainer;
