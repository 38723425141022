import { Col, Divider, Form, FormInstance, Row } from 'antd';
import { useEffect, useState } from 'react';
import icons from '../../../assets/themes/svgs';
import RadioButtonCustom from 'components/common/RadioButton';
import ButtonCustom from 'components/common/Button';
import './style.scss';
import SwitchCustom from 'components/common/Switch';
import DynamicSetting from './form/DynamicSetting';
import StakingLimit from './form/StakeLimit';
import InputForm from 'components/common/InputForm';
import {
  contractFactory,
  contractFactoryKCC,
  createAllocationPool,
  createLinerPool,
} from 'blockchain/helper/web3';
import {
  BSC_CHAIN_ID,
  exceptThisMinus,
  exceptThisSymbols,
  KCC_CHAIN_ID,
  LIMIT_CHARACTER,
  NETWORK_BSC,
  NETWORK_KCC,
  ROUTES,
} from 'common/constants';
import _ from 'lodash';
import useAuth from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { toast } from 'react-toastify';
import {
  preventAPRCharacterLimit,
  preventCharacterLimit,
  removeZeroNumber,
} from 'utils/function';
import Web3 from 'web3';
import { ethers } from 'ethers';
import PairToken from './component/PairToken';

const DEFAULT_VALUE_FORM = {
  chain: 'bsc',
  apr: 'fixed',
  _bonusMultiplier: '1',
};

const CreatePool = () => {
  const history = useHistory();
  const windowObj = window as any;
  const web3 = new Web3();
  const _rewardDistributor = process.env
    .REACT_APP_REWARD_DISTRIBUTOR as any as string;

  const { account } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isFixed, setIsFixed] = useState(true);
  const [disableCreate, setDisableCreate] = useState(false);
  const [chain, setChain] = useState<string>('bsc');
  const [form] = Form.useForm();
  const { ethereum } = windowObj;
  const network = ethereum?.networkVersion;

  const renderLabel = (title: string) => {
    return <span className="title font-600">{title}</span>;
  };

  const renderLabelRequired = (title: string) => {
    return (
      <>
        <span className="title font-600">{title}</span>
        <span className="text-required">*</span>
      </>
    );
  };
  const renderTitle = (title: string) => {
    return <span className="title-required font-600">{title}</span>;
  };
  const labelRequired = (title: string) => {
    return (
      <>
        <span className="title-required font-600">{title}</span>
        <span className="text-required">*</span>
      </>
    );
  };

  const preventDecimal = (e: any) => {
    exceptThisSymbols.includes(e.key) && e.preventDefault();
  };

  function handleDecimalsOnValue(value: any) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,1})/s;
    return value.match(regex)[0];
  }

  const limitDecimal = (
    e: React.ChangeEvent<HTMLInputElement>,
    form: FormInstance,
    limit = LIMIT_CHARACTER
  ) => {
    const { name, value } = e.target;
    form.setFieldValue([name], handleDecimalsOnValue(value.slice(0, limit)));
  };

  const preventMinus = (e: any) => {
    exceptThisMinus.includes(e.key) && e.preventDefault();
  };

  const checkNetwork = (chain: any) => {
    if (chain === 'kcc' && network === BSC_CHAIN_ID) {
      toast.error(
        <div>
          You are on a different network. <br />
          Please switch to KCC to create pool.
        </div>
      );
      throw new Error('You are on a different network. ');
    }
    if (chain === 'bsc' && network === KCC_CHAIN_ID) {
      toast.error(
        <div>
          You are on a different network. <br />
          Please switch to BSC to create pool.
        </div>
      );
      throw new Error('You are on a different network. ');
    }
  };

  //handle create
  const handleCreate = async (value: any) => {
    const pairToken = form.getFieldValue('fields');
    const newStakeToken = _.map(pairToken, '_stakeToken');
    const newRewardToken = _.map(pairToken, '_saleToken');
    let newStakeTokenRate = ['1'];

    if (newStakeToken.length > 1) {
      newStakeTokenRate = _.map(pairToken, '_stakedTokenRate') as any;
    }

    const chain = form.getFieldValue('chain');
    checkNetwork(chain);
    setLoading(true);

    if (isFixed) {
      createFixedApr(newStakeToken, newRewardToken, newStakeTokenRate, chain);
    } else {
      createDynamicApr(newStakeToken, newRewardToken, newStakeTokenRate, chain);
    }
  };
  const onFinishFailed = ({ errorFields }: any) => {
    document.getElementById(errorFields[0]?.name?.join('_'))?.focus();
  };

  const createFixedApr = async (
    newStakeToken: Array<any>,
    newRewardToken: Array<any>,
    newStakeTokenRate: Array<any>,
    chain: string
  ) => {
    let contract = contractFactory;
    let _cap = '0';
    let _lockDuration = '0';

    if (form.getFieldValue('_cap')) {
      _cap = new BigNumber(form.getFieldValue('_cap'))
        .multipliedBy(new BigNumber(10).pow(18))
        .toString(10);
    }
    if (form.getFieldValue('_lockDuration')) {
      _lockDuration = new BigNumber(
        form.getFieldValue('_lockDuration') * 24 * 3600
      ).toString(10);
    }

    const _APR = new BigNumber(form.getFieldValue('_APR'))
      .multipliedBy(new BigNumber(10).pow(18))
      .toString(10);

    const newPool = {
      _stakeToken: newStakeToken,
      _saleToken: newRewardToken,
      _stakedTokenRate: newStakeTokenRate,
      _APR: _APR,
      _cap: _cap,
      _startTimeJoin: parseInt((new Date().getTime() / 1000).toString()),
      _lockDuration: _lockDuration,
      _rewardDistributor,
    };

    if (chain === 'kcc') {
      contract = contractFactoryKCC;
    }

    try {
      const result = await createLinerPool(contract, newPool, account ?? '');
      if (result) {
        setLoading(false);
        toast.success('Created successfully');
        history.push(ROUTES.POOL_MANAGEMENT);
      }
    } catch (err) {
      console.error(err);
      toast.error('Create failed');
      setLoading(false);
    }
  };

  const createDynamicApr = async (
    newStakeToken: Array<any>,
    newRewardToken: Array<any>,
    newStakeTokenRate: Array<any>,
    chain: string
  ) => {
    let contract = contractFactory;
    let _lockDuration = '0';
    let _tokenPerBlock = '0';

    if (form.getFieldValue('_lockDuration')) {
      _lockDuration = new BigNumber(
        form.getFieldValue('_lockDuration') * 24 * 3600
      ).toString(10);
    }
    if (form.getFieldValue('_tokenPerBlock')) {
      _tokenPerBlock = new BigNumber(form.getFieldValue('_tokenPerBlock'))
        .multipliedBy(new BigNumber(10).pow(18))
        .toString(10);
    }
    const newPool = {
      _lpToken: newStakeToken,
      _rewardToken: newRewardToken,
      _stakedTokenRate: newStakeTokenRate,
      _bonusMultiplier: new BigNumber(
        form.getFieldValue('_bonusMultiplier')
      ).toString(10),
      _startBlock: new BigNumber(form.getFieldValue('_startBlock')).toString(),
      _bonusEndBlock: new BigNumber(form.getFieldValue('_endBlock')).toString(
        10
      ),
      _lockDuration: _lockDuration,
      _rewardDistributor,
      _tokenPerBlock,
    };
    if (chain === 'kcc') {
      contract = contractFactoryKCC;
    }

    try {
      const result = await createAllocationPool(
        contract,
        newPool,
        account ?? ''
      );
      setLoading(false);
      toast.success('Created successfully');
      history.push(ROUTES.POOL_MANAGEMENT);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className="create-pool">
      <Form
        form={form}
        onFinish={handleCreate}
        onFinishFailed={onFinishFailed}
        initialValues={DEFAULT_VALUE_FORM}
      >
        <Row className="row1">
          <div className="row1__left">
            <img
              className="back-icon"
              src={icons.ArrowLeft}
              alt="icon-arrow-back"
              onClick={() => {
                history.goBack();
              }}
            ></img>
            <span className="font-700 back-title">Pool name</span>
          </div>
          <div className="row1__right">
            <span className="allow-staking">Allow staking</span>
            <SwitchCustom disabled={true} checked={true} />
            <span className="stop-staking">Stop staking</span>
          </div>
        </Row>

        <Row className="content">
          <Col span={8} className="box basic-info">
            <div className="pool-index ">
              <img src={icons.Frame1} alt="Frame1" />
              <div className="pool-index__info">
                <span className="title font-500">Pool index</span>
                <span className="value font-500 ">--</span>
              </div>
            </div>
            <Divider className="divider-content" />
            <div className="liquidity">
              <img src={icons.Frame2} alt="Frame2" />
              <div className="liquidity__info">
                <span className="title font-500">Liquidity ($)</span>
                <span className="value font-500 ">--</span>
              </div>
            </div>
          </Col>

          <Col span={8} className="box basic-info">
            <span className="title font-600">Set lock period (days)</span>
            <Form.Item
              name="_lockDuration"
              style={{ margin: 0, width: '100%', marginBottom: '20px' }}
            >
              <InputForm
                name="_lockDuration"
                forminput={form}
                type="number"
                placeholder="Enter"
                style={{ marginTop: '5px' }}
                maxLength={20}
                onKeyDown={preventDecimal}
                onChange={(e) => preventCharacterLimit(e, form, 7)}
                onBlur={(e) => removeZeroNumber(e, form)}
              />
            </Form.Item>

            {isFixed ? (
              <></>
            ) : (
              <>
                <span className="title font-600">
                  {renderLabelRequired('Reward per block')}
                </span>
                <Form.Item
                  name="_tokenPerBlock"
                  style={{ margin: 0, width: '100%', marginBottom: '20px' }}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value)
                          return Promise.reject(
                            new Error(`Please fill in this field`)
                          );
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputForm
                    name="_tokenPerBlock"
                    type="number"
                    placeholder="Enter"
                    style={{ marginTop: '5px' }}
                    maxLength={20}
                    onBlur={(e) => removeZeroNumber(e, form)}
                    onChange={(e) => preventCharacterLimit(e, form)}
                  />
                </Form.Item>
              </>
            )}
          </Col>
          <Col span={8} className="box basic-info">
            <Row>
              <Col span={8}>
                <span className="title font-600">
                  {renderLabelRequired('Chain')}
                </span>
                <Form.Item name="chain">
                  <RadioButtonCustom
                    onChange={(e) => {
                      let value = e.target.value;
                      setChain(value);
                    }}
                    options={[
                      { label: 'BSC', value: 'bsc' },
                      { label: 'KCC', value: 'kcc' },
                    ]}
                  />
                </Form.Item>

                <span className="title font-600">{renderLabel('APR')}</span>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Form.Item name="apr">
                    <RadioButtonCustom
                      onChange={(event) => {
                        let value = event.target.value;
                        if (value === 'fixed') setIsFixed(true);
                        else if (value === 'dynamic') setIsFixed(false);
                      }}
                      options={[
                        { label: 'Fixed', value: 'fixed' },
                        { label: 'Dynamic', value: 'dynamic' },
                      ]}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            {isFixed ? (
              <Form.Item
                name="_APR"
                style={{ margin: 0, width: '100%' }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value)
                        return Promise.reject(
                          new Error(`Please fill in this field`)
                        );
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputForm
                  type="number"
                  placeholder="Enter"
                  name="_APR"
                  forminput={form}
                  step="0.01"
                  onKeyDown={preventMinus}
                  onBlur={(e) => removeZeroNumber(e, form)}
                  onChange={(e) => preventAPRCharacterLimit(e, form)}
                  min={0}
                  suffix={<img src={icons.PercentIcon} alt="percent"></img>}
                />
              </Form.Item>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <div className="content">
          <Row>
            <PairToken
              form={form}
              chain={chain}
              labelRequired={labelRequired}
              preventDecimal={preventDecimal}
            />
          </Row>
        </div>

        <div className="content">
          {isFixed ? (
            <StakingLimit form={form} labelRequired={labelRequired} />
          ) : (
            <DynamicSetting
              renderLabelRequired={renderLabelRequired}
              renderTitle={renderTitle}
              renderLabel={renderLabel}
              form={form}
              chain={chain}
              setDisableCreate={setDisableCreate}
            />
          )}
        </div>

        <Row className="group-button">
          <ButtonCustom
            loading={loading}
            disabled={loading || disableCreate}
            className="btn-create font-600"
            style={{ marginBottom: '52px', marginTop: '20px' }}
            htmlType="submit"
          >
            Create
          </ButtonCustom>
        </Row>
      </Form>
    </div>
  );
};

export default CreatePool;
